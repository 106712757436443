import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import api from './axiosConfig';
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import "./AdminForm.css";  
import Layout from "./Layout";

interface Workout {
  start_datetime: string;
  timer_id: string;
  location_id: string;
  playlists: {
    warmup: string;
    main: string;
    challenge: string;
    stretching: string;
  };
  bsport_id: string; // Initialize bsport_id
}

interface Timer {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

function WorkoutEdit() {
  const { workoutId } = useParams<{ workoutId: string }>();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState<Workout>({
    start_datetime: new Date().toISOString().slice(0, 16), // Initialize with current date and time (HH:mm format)
    timer_id: "",
    location_id: "",
    playlists: { warmup: "", main: "", challenge: "", stretching: "" },
    bsport_id: "", // Initialize bsport_id
  });
  const [timers, setTimers] = useState<Timer[]>([]); // List of timers for the dropdown
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (workoutId) {
          const workoutResponse = await api.get(`/workouts/${workoutId}`);

          // Format the datetime for the input field (no timezone adjustments needed here)
          const formattedDateTime = moment(workoutResponse.data.start_datetime)
            .local() // Convert to the user's local time
            .format("YYYY-MM-DDTHH:mm"); // Format in the ISO 8601 local time format

          setWorkout({
            ...workoutResponse.data,
            start_datetime: formattedDateTime,
          });
        }

        // Fetch timer names for the dropdown
        const timersResponse = await api.get("/timers?fields=id,name");
        setTimers(timersResponse.data);

        // Fetch location nanes for the dropdown
        const locationsResponse = await api.get(
          "/locations?fields=id,name"
        ); // Fetch only id and name
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [workoutId]); // Re-fetch if workoutId changes (e.g., when editing a different workout)

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setWorkout((prevWorkout) => ({ ...prevWorkout, [name]: value }));
  };

  const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const locationId = event.target.value;
    setWorkout((prevWorkout) => ({
      ...prevWorkout,
      location_id: locationId,
    }));
  };

  const handlePlaylistChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setWorkout((prevWorkout) => ({
      ...prevWorkout,
      playlists: {
        ...prevWorkout.playlists,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      // Convert the start_datetime back to UTC before saving
      const utcDateTime = moment(workout.start_datetime).utc().format(); // Convert to UTC

      const workoutToSave = {
        ...workout,
        start_datetime: utcDateTime, // Use the converted UTC time
      };

      if (workoutId) {
        await api.put(`/workouts/${workoutId}`, workoutToSave);
      } else {
        await api.post("/workouts", workoutToSave);
      }
      navigate("/workouts"); // Redirect to WorkoutPage after saving/updating
    } catch (error) {
      console.error("Error saving/updating workout:", error);
      // Handle errors appropriately (e.g., display error message to the user)
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
    <div className="admin-form-container">
      <h2>{workoutId ? "Edit Workout" : "Create New Workout"}</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="start_datetime">Start Date/Time:</label>
              </td>
              <td>
                <input
                  type="datetime-local"
                  id="start_datetime"
                  name="start_datetime"
                  value={workout.start_datetime}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            {/* Location Dropdown */}
            <tr>
              <td>
                <label htmlFor="location_id">Location:</label>
              </td>
              <td>
                <select
                  id="location_id"
                  name="location_id"
                  value={workout.location_id}
                  onChange={handleLocationChange}
                >
                  <option value="">Select a Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="timer_id">Timer:</label>
              </td>
              <td>
                <select
                  id="timer_id"
                  name="timer_id"
                  value={workout.timer_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a Timer</option>
                  {timers.map((timer) => (
                    <option key={timer.id} value={timer.id}>
                      {timer.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label>Bsport ID:</label>
              </td>
              <td>
                  {workout.bsport_id || "Not set"}
              </td>
            </tr>
          </tbody>
        </table>
        {/* Playlist Links */}
        <div className="playlist-links">
          <h3>Playlists:</h3>
          <div>
            <label htmlFor="warmup">Warm-up:</label>
            <input
              type="text"
              id="warmup"
              name="warmup"
              value={workout.playlists?.warmup ?? ""}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="main">Main Workout:</label>
            <input
              type="text"
              id="main"
              name="main"
              value={workout.playlists?.main ?? ""}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="challenge">Challenge:</label>
            <input
              type="text"
              id="challenge"
              name="challenge"
              value={workout.playlists?.challenge ?? ""}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="stretching">Stretching:</label>
            <input
              type="text"
              id="stretching"
              name="stretching"
              value={workout.playlists?.stretching ?? ""}
              onChange={handlePlaylistChange}
            />
          </div>
        </div>
        <button type="submit">
          {workoutId ? "Update Workout" : "Create Workout"}
        </button>
      </form>
      </div>
    </Layout>
  );
}

export default WorkoutEdit;
