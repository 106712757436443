import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext"; 

interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }: ProtectedRouteProps) => {
  const { user, userRoles, isLoading, isAuthenticating } = useAuth();
  const location = useLocation();

  console.log("ProtectedRoute: isLoading:", isLoading, "isAuthenticating:", isAuthenticating, "user:", user);

  if (isLoading || isAuthenticating) {
    console.log("Loading or authenticating...");
    return null; // Don't render anything yet
  }

  if (!user) {
    console.log("User not authenticated. Redirecting to login...");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && !userRoles?.some((role: string) => allowedRoles.includes(role))) {
    console.log("User does not have the required roles. Redirecting to unauthorized...");
    return <Navigate to="/unauthorized" state={{ from: location }} replace />; 
  }

  return <>{children}</>; // Render the child route's component
}

export default ProtectedRoute;