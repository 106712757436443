import React from 'react';
import './LocationTablet.css';

interface TwoChoiceModalProps {
  isOpen: boolean;
  onResponse: (choice: boolean) => void;
  message: string;
  aText?: string;
  bText?: string;
}

const TwoChoiceModal: React.FC<TwoChoiceModalProps> = ({
  isOpen,
  onResponse,
  message,
  aText = "Yes",
  bText = "No"
}) => {
  if (!isOpen) return null;

  const handleConfirm = () => {
    onResponse(true);
  };

  const handleCancel = () => {
    onResponse(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-buttons">
          <button className="button-s" onClick={handleConfirm}>{aText}</button>
          <button className="button-s secondary" onClick={handleCancel}>{bText}</button>
        </div>
      </div>
    </div>
  );
};

export default TwoChoiceModal;
