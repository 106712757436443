import React, { useState, ChangeEvent } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./LoginSignup.css";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from "./AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AxiosError } from 'axios';
import api from "./axiosConfig";

function LoginSignup() {
  const auth = getAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const [showSignupForm, setShowSignupForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const { setIsAuthenticating, login } = useAuth();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError(null);
  };

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    setError(null);
  };

  const handleEmailContinue = async () => {
    if (!isEmailValid) {
      setError("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const existingUserResponse = await api.get(`/users/email/${email}`);
      console.log(existingUserResponse.data);
      if (existingUserResponse.data.firebase_uid) {
        setShowLoginForm(true);
      } else {
        setShowSignupForm(true);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        setShowSignupForm(true);
      } else {
        setError("An error occurred. Please try again later. Error code: " + err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      login(user);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    setError(null);

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

    if (!passwordRegex.test(password)) {
      setError(
        "Password must be at least 10 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      console.log(user);
      const response = await api.post("/users", {
        email: user.email,
        firebaseUid: user.uid,
        first_name: firstName,
        last_name: lastName
      });

      // Check if both user and customer creation were successful
      if (response.status === 201) {
        login(user); // Update authentication state after successful creation
      } else {
        // Handle the error case (e.g., display an error message to the user)
        setError("Failed to create user and customer.");
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsAuthenticating(true);

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();

      try {
        // Check if user exists (anticipate potential 404)
        await api.get(`/users/email/${user.email}`);

        // User exists, log them in
        setIsAuthenticating(false);
        login(user);
        console.log("Logging in because user exists");
      } catch (err) {
        const axiosError = err as AxiosError;
        if (axiosError.response && axiosError.response.status === 404) {
          // User not found, proceed to create them
          const response = await api.post(
            "/users",
            {
              email: user.email,
              firebaseUid: user.uid,
              first_name: user.displayName?.split(" ")[0] || "",
              last_name: user.displayName?.split(" ")[1] || "",
            },
            {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (response.status === 201) {
            setIsAuthenticating(false);
            login(user);
          } else {
            setError("Failed to create user.");
          }
        } else {
          // Handle other errors (e.g., network or server issues)
          setError("An error occurred. Please try again later.");
        }
      }
    } catch (err) {
      setError((err as Error).message); // Handle Firebase authentication errors
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email address to reset your password.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Please check your inbox.");
    } catch (err: any) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="default-container">
      <img src="/logo_text.svg" alt="Logo"/>
      <div className="login-signup-container">
        <div>
          <h1>
            {showSignupForm
              ? "Create a new account"
              : showLoginForm
              ? "Login"
              : "Login/Signup"}
          </h1>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {!showSignupForm && !showLoginForm && (
              <>
                <button onClick={handleEmailContinue} disabled={isLoading}>
                  Continue
                </button>
                <div className="separator">
                  <span className="or-text">or</span>
                </div>
              </>
            )}
          </div>

          {showSignupForm && ( // Sign Up Form (only shown after email input)
            <div>
              <div>
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="password-container">
                <label htmlFor="password">Password:</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              <div className="password-container">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              <button onClick={handleSignUp} disabled={isLoading}>
                Create Account
              </button>
            </div>
          )}

          {/* Login Form (only shown after email input) */}
          {showLoginForm && (
            <div>
              <div className="password-container">
                <label htmlFor="password">Password:</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                <p className="forgot-password" onClick={handleForgotPassword}>
                  Forgot password?
                </p>
              </div>
              <button onClick={handleLogin} disabled={isLoading}>
                Login
              </button>
            </div>
          )}

          {/* Google Sign-In Button (only shown initially) */}
          {!showSignupForm && !showLoginForm && (
            <button onClick={handleGoogleSignIn} disabled={isLoading}>
              <span>
                <FcGoogle />
                Sign In with Google
              </span>
            </button>
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default LoginSignup;