import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import api from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import "./AdminForm.css"; 
import Layout from "./Layout";

interface Workout {
  start_datetime: string;
  timer_id: string;
  location_id: string;
  playlists: {
    warmup: string;
    main: string;
    challenge: string;
    stretching: string;
  };
}

interface Timer {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

function WorkoutCreate() {
  const navigate = useNavigate();
  const [workout, setWorkout] = useState<Workout>({
    start_datetime: new Date().toISOString().slice(0, 16),
    timer_id: '',
    location_id: '',
    playlists: { warmup: '', main: '', challenge: '', stretching: '' }
  });
  const [timers, setTimers] = useState<Timer[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [timersResponse, locationsResponse] = await Promise.all([
          api.get<Timer[]>('/timers?fields=id,name'),
          api.get<Location[]>('/locations?fields=id,name')
        ]);
        setTimers(timersResponse.data);
        setLocations(locationsResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch timers or locations');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setWorkout(prevWorkout => ({ ...prevWorkout, [name]: value }));
  };

  const handleLocationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const locationId = event.target.value;
    setWorkout(prevWorkout => ({
      ...prevWorkout,
      location_id: locationId,
    }));
  };

  const handlePlaylistChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setWorkout(prevWorkout => ({
      ...prevWorkout,
      playlists: {
        ...prevWorkout.playlists,
        [name]: value,
      }
    }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      await api.get('/workout_structures/check', {
        params: {
          timer_id: workout.timer_id,
          location_id: workout.location_id
        }
      });
      await api.post('/workouts', workout);
      navigate('/workouts');
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        setError(err.response.data.error); // Structure not found
      } else {
        console.error('Error creating workout:', err);
        setError('Failed to create workout');
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
    <div className="admin-form-container">
      <h2>Create New Workout</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            
            <tr>
              <td><label htmlFor="start_datetime">Start Date/Time:</label></td>
              <td><input type="datetime-local" id="start_datetime" name="start_datetime" value={workout.start_datetime} onChange={handleChange} required /></td>
            </tr>
            <tr>
              <td>
                <label htmlFor="location_id">Location:</label>
              </td>
              <td>
                <select
                  id="location_id"
                  name="location_id"
                  value={workout.location_id}
                  onChange={handleLocationChange}
                >
                  <option value="" disabled hidden>Select a Location</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td><label htmlFor="timer_id">Timer:</label></td>
              <td>
                <select id="timer_id" name="timer_id" value={workout.timer_id} onChange={handleChange} required>
                  <option value="" disabled hidden>Select a Timer</option>
                  {timers.map(timer => (
                    <option key={timer.id} value={timer.id}>{timer.name}</option>
                  ))}
                </select>
              </td>
            </tr>
            </tbody>
        </table>
        {/* Playlist Links */}
        <div className="playlist-links">
          <h3>Playlists:</h3>
          <div>
            <label htmlFor="warmup">Warm-up:</label>
            <input
              type="text"
              id="warmup"
              name="warmup"
              value={workout.playlists.warmup}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="main">Main Workout:</label>
            <input
              type="text"
              id="main"
              name="main"
              value={workout.playlists.main}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="challenge">Challenge:</label>
            <input
              type="text"
              id="challenge"
              name="challenge"
              value={workout.playlists.challenge}
              onChange={handlePlaylistChange}
            />
          </div>
          <div>
            <label htmlFor="stretching">Stretching:</label>
            <input
              type="text"
              id="stretching"
              name="stretching"
              value={workout.playlists.stretching}
              onChange={handlePlaylistChange}
            />
          </div>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={loading}>Create Workout</button>
      </form>
    </div>
    </Layout>
  );
}
export default WorkoutCreate;