import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import api from './axiosConfig';
import { useNavigate, useParams } from "react-router-dom";
import "./AdminForm.css"; // Import the common admin form CSS

interface Location {
  name: string;
  latitude: string;
  longitude: string;
  google_maps_link: string;
  address: string;
  zip_code: string;
  city: string;
  max_spots: number;
  bsport_id: string;
  floorplan_url: string;
  zone_id: string;
}

function LocationEdit() {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const [location, setLocation] = useState<Location>({
    name: "",
    latitude: "",
    longitude: "",
    google_maps_link: "",
    address: "",
    zip_code: "",
    city: "",
    max_spots: 0,
    bsport_id: "",
    floorplan_url: "",
    zone_id: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (locationId) {
          const locationResponse = await api.get<Location>(
            `/locations/${locationId}`
          );
          setLocation(locationResponse.data);
        }
      } catch (err) {
        console.error("Error fetching location:", err);
        setError("Failed to fetch location");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [locationId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocation((prevLocation) => ({ ...prevLocation, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (locationId) {
        await api.put(`/locations/${locationId}`, location);
      } else {
        await api.post("/locations", location);
      }
      navigate("/locations");
    } catch (err) {
      console.error("Error saving/updating location:", err);
      setError("Failed to save location");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-form-container">
      <h2>Edit Location</h2>
      <form onSubmit={handleSubmit}>
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="name">Name:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={location.name}
                  onChange={handleChange}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="latitude">Latitude:</label>
              </td>
              <td>
                <input
                  type="number"
                  id="latitude"
                  name="latitude"
                  step="0.000001"
                  value={location.latitude}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="longitude">Longitude:</label>
              </td>
              <td>
                <input
                  type="number"
                  id="longitude"
                  name="longitude"
                  step="0.000001"
                  value={location.longitude}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="google_maps_link">Google Maps Link:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="google_maps_link"
                  name="google_maps_link"
                  value={location.google_maps_link}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="address">Address:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={location.address}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="zip_code">Zip Code:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  value={location.zip_code}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="city">City:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={location.city}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="max_spots">Max Spots:</label>
              </td>
              <td>
                <input
                  type="number"
                  id="max_spots"
                  name="max_spots"
                  value={location.max_spots}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="bsport_id">Bsport ID:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="bsport_id"
                  name="bsport_id"
                  value={location.bsport_id}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="floorplan_url">Floorplan URL:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="floorplan_url"
                  name="floorplan_url"
                  value={location.floorplan_url}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="zone_id">Zone ID:</label>
              </td>
              <td>
                <input
                  type="text"
                  id="zone_id"
                  name="zone_id"
                  value={location.zone_id}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" disabled={isLoading}>
          Update Location
        </button>
      </form>
    </div>
  );
}

export default LocationEdit;
