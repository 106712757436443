import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css"; // Ensure this CSS file exists for styling
import Layout from "./Layout";

const HomePage: React.FC = () => {
  return (
    <Layout>
      <div className="admin-list-container">
        <h1>Workout Software v1</h1>
        <ul>
          <p>
            <Link to="/timers">Go to Timer List</Link>
          </p>
          <p>
            <Link to="/workouts">Go to Workout List</Link>
          </p>
          <p>
            <Link to="/bookings">Go to Booking List</Link>
          </p>
          <p>
            <Link to="/locations">Go to Location List</Link>
          </p>
          <p>
            <Link to="/customers">Go to Customer List</Link>
          </p>
          <p>
            <Link to="/exercises">Go to Exercise List</Link>
          </p>
          <p>
            <Link to="/wods">Go to WOD List</Link>
          </p>
        </ul>
      </div>
    </Layout>
  );
}

export default HomePage;
