import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig';
import "./SpotPlayer.css";
import { getNextWeight } from './utils/weightUtils';

interface WorkoutInterval {
  duration: number;
  currentExerciseReps: number;
  currentCustomerId: number | null;
  currentExerciseId: string;
  currentExerciseEquipment: string;
  setNumber: number;
  intervalType: string;
  suggestedWeight: number;
  currentExerciseNumber: number;
  currentExerciseName: string;
  currentExerciseVideo: string;
  nextExerciseVideo: string;
  currentCustomerFirstName: string;
  currentCustomerLastName: string;
  nextExerciseReps: number;
  customerNumber: number;
  nextCustomerNumber: number;
}

interface Customer {
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string;
  booking_id: string;
}

function SpotPlayer() {
  const { locationId, spot } = useParams<{ locationId: string; spot: string }>();
  const [workoutId, setWorkoutId] = useState<number | null>(null);
  const [workoutSequence, setWorkoutSequence] = useState<WorkoutInterval[]>([]);
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [weightAdjustments, setWeightAdjustments] = useState<Record<string, number>>({});
  const [displayedWeight, setDisplayedWeight] = useState<string>("");
  const [customersWithoutSpot, setCustomersWithoutSpot] = useState<Customer[]>([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const currentInterval = workoutSequence[currentIntervalIndex] || {};
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [ws, setWs] = useState<WebSocket | null>(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/workouts/${workoutId}/spot/${spot}`);
      setWorkoutId(response.data.workout.id);
      setWorkoutSequence(response.data.workoutSequence);

      const customersResponse = await api.get(`/bookings?workout_id=${workoutId}&no_spot&fields=booking_id`);
      setCustomersWithoutSpot(customersResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (workoutId && spot) {
      fetchData();
    }
  }, [workoutId, spot]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && workoutSequence.length > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            return prevTimeLeft - 1;
          } else {
            const nextIntervalIndex = currentIntervalIndex + 1;

            if (nextIntervalIndex < workoutSequence.length) {
              setCurrentIntervalIndex(nextIntervalIndex);
              setTimeLeft(workoutSequence[nextIntervalIndex].duration);
            } else {
              clearInterval(intervalId);
              setIsRunning(false);
            }

            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, currentIntervalIndex, workoutSequence, displayedWeight]);

  const handleCustomerAssign = async (bookingId: string) => {
    try {
      await api.put(`"/bookings/${bookingId}/assign_spot`);

      setCustomersWithoutSpot(customersWithoutSpot.filter((c) => c.booking_id !== bookingId));

    } catch (error) {
      console.error("Error assigning customer:", error);
    }
  };

  useEffect(() => {
    if (timeLeft === 0 && currentInterval && currentInterval.currentExerciseReps > 0) {
      const exerciseLogData = {
        customer_id: currentInterval.currentCustomerId,
        workout_id: workoutId,
        exercise_id: currentInterval.currentExerciseId,
        equipment: currentInterval.currentExerciseEquipment,
        spot: spot,
        set_number: currentInterval.setNumber,
        set_type: currentInterval.intervalType,
        reps: currentInterval.currentExerciseReps,
        suggested_weight: currentInterval.suggestedWeight,
        actual_weight: displayedWeight,
        exercise_number: currentInterval.currentExerciseNumber,
      };

      api
        .post("/exercise_logs", exerciseLogData)
        .then((response) => {
          console.log("Exercise log created:", response.data);
        })
        .catch((error) => {
          console.error("Error creating exercise log:", error);
        });
    }
  }, [timeLeft, currentInterval, workoutId, displayedWeight, spot]);

  const updateWeight = (key: string, newActualWeight: number) => {
    setWeightAdjustments((prevWeights) => ({
      ...prevWeights,
      [key]: newActualWeight,
    }));
  };

  const getWeightIncrement = (equipment: string): number =>
    !equipment
      ? 2.0
      : equipment.startsWith("Dumbbell") || equipment.startsWith("Barbell")
      ? 2.0
      : equipment.startsWith("Cable")
      ? 2.5
      : 5.0;

  const handleWeightChange = (change: number) => {
    if (!currentInterval) return;

    if (currentInterval.currentExerciseNumber) {
      const key = `${currentInterval.customerNumber}-${currentInterval.currentExerciseId}-${currentInterval.setNumber}`;
      const existingWeight = weightAdjustments[key] || currentInterval.suggestedWeight;
      const increment = getWeightIncrement(currentInterval.currentExerciseEquipment);
      // Ensure the increment is applied correctly
      updateWeight(key, Math.max(0, parseFloat(existingWeight.toString()) + parseFloat((change * increment).toString())));
      setShowButtons(false); // Hide buttons after pressing
    } else if (
      currentInterval.intervalType === "switch" ||
      currentInterval.intervalType === "pause" ||
      currentInterval.intervalType === "setup" ||
      currentInterval.intervalType === "change_station"
    ) {
      const nextInterval = workoutSequence[currentIntervalIndex + 1];
      if (nextInterval && nextInterval.currentExerciseNumber) {
        const key = `${nextInterval.customerNumber}-${nextInterval.currentExerciseId}-${nextInterval.setNumber}`;
        const existingWeight = weightAdjustments[key] || nextInterval.suggestedWeight;
        const increment = getWeightIncrement(nextInterval.currentExerciseEquipment);
        // Ensure the increment is applied correctly
        updateWeight(key, Math.max(0,parseFloat(existingWeight.toString()) + parseFloat((change * increment).toString())));
        setShowButtons(false); // Hide buttons after pressing
      }
    }
  };

  useEffect(() => {
    if (!currentInterval) return;

    const relevantInterval =
      currentInterval.intervalType === "switch" ||
      currentInterval.intervalType === "setup" ||
      currentInterval.intervalType === "change_station"
        ? workoutSequence[currentIntervalIndex + 1]
        : currentInterval;

    if (relevantInterval && relevantInterval.currentExerciseReps > 0) {
      let adjustedWeight = null;
      for (let i = relevantInterval.setNumber; i >= 1; i--) {
        const setKey = `${relevantInterval.customerNumber}-${relevantInterval.currentExerciseId}-${i}`;
        if (weightAdjustments[setKey]) {
          adjustedWeight = weightAdjustments[setKey];
          break;
        }
      }
      if (adjustedWeight !== null) {
        setDisplayedWeight(adjustedWeight.toString());
      } else {
        setDisplayedWeight(relevantInterval.suggestedWeight.toString());
      }

      // if no weight adjustment has been made for the specific set of the current interval
      if (!showButtons &&
        !weightAdjustments.hasOwnProperty(
          `${relevantInterval.customerNumber}-${relevantInterval.currentExerciseId}-${relevantInterval.setNumber}`
        )
      ) {
        setShowButtons(true);
      }
    }

  }, [workoutSequence, currentIntervalIndex, weightAdjustments]);

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = process.env.NODE_ENV === 'production'
        ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
        : "ws://localhost:5000";

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log(`SpotPlayer ${spot} connected to WebSocket server`);
        newWs.send(JSON.stringify({ type: "join", locationId }));
      };

      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received message in SpotPlayer:", data);

        if ((data.type === "timerUpdate" || data.type === 'refreshTimer' || data.type === 'resetTimer') &&
          data.locationId === locationId) {
          setWorkoutId(data.workoutId);
          setCurrentIntervalIndex(data.timerState.intervalIndex);
          setTimeLeft(data.timerState.timeLeft);
          setIsRunning(data.timerState.isRunning);
        } else if (
          data.type === "bookingUpdate" &&
          data.workoutId === workoutId
        ) {
          fetchData(); // Refetch data when a customer is assigned
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        newWs.close();
      };

      setWs(newWs);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [workoutId]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const formatWeight = (weight: string) => {
    const weightStr = weight.toString();
    const formattedWeight = weightStr.replace(/\.0+$/, "");
    return formattedWeight;
  };

  const capitalizeFirstLetter = (string: string) => {
    const stringWithSpaces = string.replace(/_/g, " ");
    return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
  };

  const formatCustomerName = (firstName: string, lastName: string) => {
    if (!firstName || !lastName) return "";
    return `${firstName} ${lastName.slice(0, 2)}.`;
  };

  const specialIntervalTypes = [
    "explanation",
    "warmup",
    "challenge",
    "stretching",
    "breathing",
    "break",
    "pause"
  ];

  const checkInIntervalTypes = ["explanation", "warmup"];

  const calculateProgress = () => {
    if (!currentInterval.duration) return 0;
    return (
      ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="loading-container">
          <p>Loading workout...</p>
        </div>
      ) : (
        <div className="spot-player-container">
        <>
          {specialIntervalTypes.includes(currentInterval.intervalType) ||
          currentInterval.currentCustomerId == null ? (
            <>
              <div className="spot-overlay"></div>
              <div className="spot-overlay-text">Lifted</div>
            </>
          ) : (
            <>
             {!currentInterval.currentExerciseNumber && (
                <>
              <div className="spot-display-overlay"></div>
              <div className="spot-display-overlay-text">
                <div className="spot-display-overlay-title">
                  {capitalizeFirstLetter(currentInterval.intervalType)}
                </div>
                <div className="spot-display-subtitle">Get ready</div>
              </div>
              </>
              )}
              <div className="spot-exercise-display">
                <div className="spot-exercise-card">
                  {currentInterval.currentExerciseName}
                </div>
                <video
                  src={
                    currentInterval.currentExerciseVideo ||
                    currentInterval.nextExerciseVideo
                  }
                  autoPlay
                  loop
                  muted
                  className="spot-exercise-video"
                />
              </div>
            </>
          )}

          <div className="spot-card">
            {currentInterval.currentCustomerId == null ? (
              <>
                {checkInIntervalTypes.includes(currentInterval.intervalType) ? (
                  <>
                    <p className="spot-interval-type">Check In</p>
                    <div className="spot-customer-cards-container">
                      {customersWithoutSpot.map((customer) => (
                        <div
                          key={customer.booking_id}
                          className="spot-customer-card"
                          onClick={() => handleCustomerAssign(customer.booking_id)} // Add onClick event
                        >
                          <div className="profile-avatar-s">
                            {customer.customer_profile_image_url ? (
                              <img
                                src={customer.customer_profile_image_url}
                                alt={`${customer.customer_first_name} ${customer.customer_last_name}`}
                                className="spot-profile-image"
                              />
                            ) : (
                              <div className="profile-placeholder-s">
                                {customer.customer_first_name.charAt(0)}
                                {customer.customer_last_name.charAt(0)}
                              </div>
                            )}
                          </div>
                          <div>
                            {formatCustomerName(customer.customer_first_name, customer.customer_last_name)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="spot-interval-type">No customer</p>
                  </>
                )}
              </>
            ) : (
              <>
                {specialIntervalTypes.includes(currentInterval.intervalType) ? (
                  <>
                    <p className="spot-interval-type">
                      {capitalizeFirstLetter(currentInterval.intervalType)}
                    </p>
                    <p className="spot-customer-name">
                      {formatCustomerName(
                        currentInterval.currentCustomerFirstName,
                        currentInterval.currentCustomerLastName
                      )}
                    </p>
                  </>
                ) : (
                  <div className="spot-one-column-layout">
                    <div className="spot-timer-text">
                      {formatTime(timeLeft)}
                    </div>
                    <div className="spot-progress-container">
                      <div className="spot-progress-bar">
                        <div
                          className="spot-progress-bar-fill"
                          style={{ width: `${calculateProgress()}%` }}
                        ></div>
                      </div>
                      <div className="spot-progress-time-container">
                        <span className="spot-progress-time">00:00</span>
                        <span className="spot-progress-time">
                          {Math.floor(currentInterval.duration / 60)
                            .toString()
                            .padStart(2, "0") +
                            ":" +
                            (currentInterval.duration % 60)
                              .toString()
                              .padStart(2, "0")}
                        </span>
                      </div>
                    </div>
                    <div className="spot-customer-name">
                      {formatCustomerName(
                        currentInterval.currentCustomerFirstName,
                        currentInterval.currentCustomerLastName
                      )}
                    </div>
                    {currentInterval.currentExerciseReps > 0 ? (
                      <p>{currentInterval.currentExerciseReps} Reps</p>
                    ) : currentInterval.nextExerciseReps > 0 ? (
                      <p>{currentInterval.nextExerciseReps} Reps</p>
                    ) : null}
                    <p>{formatWeight(displayedWeight)} kg</p>
                    {showButtons && (
                      <div className="spot-weight-buttons-container">
                        <div className="spot-button-column">
                          {[-1, -2, -3].map((change) => (
                            <button
                              key={change}
                              onClick={() => handleWeightChange(change * -1)}
                            >
                              +{" "}
                              {Math.abs(
                                change *
                                  getWeightIncrement(
                                    currentInterval.currentExerciseEquipment ||
                                      workoutSequence[currentIntervalIndex + 1]
                                        ?.currentExerciseEquipment
                                  )
                              )}{" "}
                              kg
                            </button>
                          ))}
                        </div>
                        <div className="spot-button-column">
                          {[-1, -2, -3].map((change) => (
                            <button
                              key={change}
                              onClick={() => handleWeightChange(change)}
                            >
                              -{" "}
                              {-1 *
                                change *
                                getWeightIncrement(
                                  currentInterval.currentExerciseEquipment ||
                                    workoutSequence[currentIntervalIndex + 1]
                                      ?.currentExerciseEquipment
                                )}{" "}
                              kg
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </>
        
        </div>
      )}
      </div>
  );
  
}

export default SpotPlayer;