import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";

interface LocationSpotSelectionProps {
    floorplanUrl: string;
    maxSpots: number;
    bookedSpots: string[];
    onClose: () => void;
    onSave: (selectedSpot: string) => void;
    greeting: string;
    displayNumber?: string;
}

const LocationSpotSelection: React.FC<LocationSpotSelectionProps> = ({
    floorplanUrl,
    maxSpots,
    bookedSpots,
    onClose,
    onSave,
    greeting,
    displayNumber
}) => {
    const [selectedSpot, setSelectedSpot] = useState<string | null>(null);

    const handleSpotClick = (spot: string) => {
        setSelectedSpot(spot);
    };

    const handleSave = () => {
        if (selectedSpot) {
            onSave(selectedSpot);
        }
    };

    const getSpots = () => {
        const totalSpots = Math.floor(maxSpots / 6);
        const letters = Array.from({ length: totalSpots }, (_, i) => String.fromCharCode(65 + i)); // Generate letters A, B, C, etc.
        const spots: string[] = [];

        // Generate all spots with numbers first then letters if displayNumber is provided
        if (displayNumber) {
            for (let i = 1; i <= 6; i++) {
                for (const letter of letters) {
                    spots.push(`${i}${letter}`); 
                }
            }
        } else {
            for (const letter of letters) {
                for (let i = 1; i <= 6; i++) {
                    spots.push(`${i}${letter}`); 
                }
            }
        }
        return spots;
    };
    
    return (
        <>
            <div className="flex-vertical gap-xs">
                <div className="top-info-buttons">
                    <button className="button-s secondary square" onClick={onClose}>
                        <CloseIcon className="icon-l" />
                    </button>
                </div>
                <div className="flex-vertical">
                    <h2>{greeting}</h2>
                    <h1>select a spot!</h1>
                </div>
            </div>
            {greeting.includes('coach') && <img src={floorplanUrl} alt="Floorplan" className="full-width" />}
            <div className="spots-container">
                {getSpots().map(spot => {
                    const isBooked = bookedSpots.includes(spot);
                    const isSelected = selectedSpot === spot;

                    return (
                        <button
                            key={spot}
                            className={`button-circle ${greeting.includes('coach') ? '' : 'large'} ${isSelected ? 'active' : ''}`}
                            onClick={() => !isBooked && handleSpotClick(spot)}
                            disabled={isBooked}
                        >
                            {spot}
                        </button>
                    );
                })}
            </div>
            <button className="button-medium special" onClick={handleSave}>
                Save
            </button>
        </>
    );
};

export default LocationSpotSelection;
