import React, { useState, useEffect, ChangeEvent } from "react";
import api from './axiosConfig';
import { useParams, Link } from "react-router-dom";
import "./AdminForm.css";
import { FaEdit, FaCheck, FaTimes, FaPencilAlt } from "react-icons/fa";
import Layout from "./Layout";
import { weightIncrements } from './utils/weightUtils';

interface Customer {
  id: string;
  first_name: string;
  last_name: string;
  sex: string;
  birthdate: string;
  created_at: string;
  user_id: string;
  bsport_id: number;
  email: string;
  height: number;
  weight: number;
  total_workouts: number;
}

interface CustomerStrengthProfile {
  id: string;
  exercise_id: string;
  exercise_name?: string;
  exercise_short_name?: string;
  equipment?: string;
  weight_1rm?: number;
  weight_4rm?: number;
  weight_6rm?: number;
  weight_8rm?: number;
  weight_12rm?: number;
}

const CustomerDetails: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [strengthProfiles, setStrengthProfiles] = useState<CustomerStrengthProfile[]>([]);
  const [editingProfileId, setEditingProfileId] = useState<string | null>(null);
  const [editedProfile, setEditedProfile] = useState<Partial<CustomerStrengthProfile>>({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const customerResponse = await api.get(
          `/customers/${customerId}`
        );
        const customer = customerResponse.data;
        setCustomer(customer);

        const rolesResponse = await api.get<string[]>(
          `/users/${customer.user_id}/roles`
        );
        setRoles(rolesResponse.data);

        const profilesResponse = await api.get<CustomerStrengthProfile[]>(
          `/customer_strength_profiles?customer_id=${customerId}`
        );
        setStrengthProfiles(profilesResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch customer data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [customerId]);

  const handleEditProfile = (profile: CustomerStrengthProfile) => {
    setEditingProfileId(profile.id);
    setEditedProfile({
      exercise_id: profile.exercise_id,
      weight_1rm: profile.weight_1rm,
      weight_4rm: profile.weight_4rm,
      weight_6rm: profile.weight_6rm,
      weight_8rm: profile.weight_8rm,
      weight_12rm: profile.weight_12rm
    });
  };

  const handleProfileInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof CustomerStrengthProfile
  ) => {
    const { value } = event.target;
    setEditedProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value === "" ? 0 : parseFloat(value),
    }));
  };

  const handleSaveProfile = async (id: string) => {
    try {
      console.log(editedProfile)
      await api.put(`/customer_strength_profiles/${id}`, editedProfile);
      setEditingProfileId(null);
      const response = await api.get<CustomerStrengthProfile[]>(
        `/customer_strength_profiles?customer_id=${customerId}`
      );
      setStrengthProfiles(response.data);
    } catch (error) {
      console.error("Error updating strength profile:", error);
      setError("Failed to update strength profile");
    }
  };

  const handleCancelEdit = () => {
    setEditingProfileId(null);
    setEditedProfile({});
  };

  const renderEditableCell = (profile: CustomerStrengthProfile, field: keyof CustomerStrengthProfile) => {
    if (editingProfileId === profile.id) {
      const equipmentType = profile.equipment as keyof typeof weightIncrements;
      if (!equipmentType) return null;

      const weights = weightIncrements[equipmentType] || [];
      const currentWeight = editedProfile[field] as number;

      // Find closest available weight if current weight exists
      let selectedValue = "";
      if (currentWeight && currentWeight > 0) {
        const closestWeight = weights.reduce((prev, curr) => {
          return Math.abs(curr - currentWeight) < Math.abs(prev - currentWeight) ? curr : prev;
        });
        selectedValue = closestWeight.toString();
      }

      return (
        <select
          value={selectedValue}
          onChange={(e) => handleProfileInputChange(e, field)}
        >
          <option value="">None</option>
          {weights.map(weight => (
            <option key={weight} value={weight}>{weight}</option>
          ))}
        </select>
      );
    } else {
      return <span>{profile[field] || ""}</span>;
    }
  };

  if (isLoading) {
    return (
      <div className="admin-form-container">
        <p>Loading customer details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-form-container">
        <p className="error-message">Error: {error}</p>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="admin-form-container">
        <p className="error-message">Customer not found.</p>
      </div>
    );
  }

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Customer Details</h2>
        <table className="info-table">
          <tbody>
            <tr>
              <td>
                <label>First Name:</label>
              </td>
              <td>{customer.first_name}</td>
            </tr>
            <tr>
              <td>
                <label>Last Name:</label>
              </td>
              <td>{customer.last_name}</td>
            </tr>
            <tr>
              <td>
                <label>Sex:</label>
              </td>
              <td>{customer.sex}</td>
            </tr>
            <tr>
              <td>
                <label>Birthdate:</label>
              </td>
              <td>{customer.birthdate}</td>
            </tr>
            <tr>
              <td>
                <label>Sign Up:</label>
              </td>
              <td>{customer.created_at}</td>
            </tr>
            <tr>
              <td>
                <label>Roles:</label>
              </td>
              <td>
                {roles.length === 0 ? (
                  <p>No roles assigned</p>
                ) : (
                  <p>{roles.join(", ")}</p>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label>BSport ID:</label>
              </td>
              <td>{customer.bsport_id}</td>
            </tr>
            <tr>
              <td>
                <label>Email:</label>
              </td>
              <td>{customer.email}</td>
            </tr>
            <tr>
              <td>
                <label>Height:</label>
              </td>
              <td>{customer.height}</td>
            </tr>
            <tr>
              <td>
                <label>Weight:</label>
              </td>
              <td>{customer.weight}</td>
            </tr>
            <tr>
              <td>
                <label>Total Workouts:</label>
              </td>
              <td>{customer.total_workouts}</td>
            </tr>
          </tbody>
        </table>
        <h3>Strength Profiles</h3>
        <table className="admin-list-table">
          <thead>
            <tr>
              <th>Exercise</th>
              <th>Short Name</th>
              <th>1RM</th>
              <th>4RM</th>
              <th>6RM</th>
              <th>8RM</th>
              <th>12RM</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {strengthProfiles.map((profile) => (
              <tr key={profile.id}>
                <td>{profile.exercise_name || profile.exercise_id}</td>
                <td>{profile.exercise_short_name}</td>
                <td>{renderEditableCell(profile, "weight_1rm")}</td>
                <td>{renderEditableCell(profile, "weight_4rm")}</td>
                <td>{renderEditableCell(profile, "weight_6rm")}</td>
                <td>{renderEditableCell(profile, "weight_8rm")}</td>
                <td>{renderEditableCell(profile, "weight_12rm")}</td>
                <td>
                  {editingProfileId === profile.id ? (
                    <div className="actions">
                      <button className="button" onClick={() => handleSaveProfile(profile.id)}>
                        <FaCheck title="Save Profile" />
                      </button>
                      <button className="button" onClick={handleCancelEdit}>
                        <FaTimes title="Cancel Edit" />
                      </button>
                    </div>
                  ) : (
                    <button onClick={() => handleEditProfile(profile)}>
                      <FaPencilAlt title="Edit Profile" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <Link to={`/customer/${customer.id}/edit`}>
            <button>
              <FaEdit title="Edit Customer" />
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDetails;
