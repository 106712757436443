import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import "./AdminList.css";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaPencilAlt, FaCopy, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";

// Define the Customer interface
interface Customer {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  sex: string;
  weight: number;
  height: number;
  birthdate?: string;
  created_at?: string;
  total_workouts: number;
  bsport_id: string; // {{ edit_1 }} Added bsport_id to the Customer interface
}

function CustomerPage() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);
      try {
        const response = await api.get<Customer[]>("/customers");
        setCustomers(response.data);
      } catch (error) {
        console.error("Error fetching customers:", error);
        setError("Failed to fetch customers");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleCopy = (customerId: string) => {
    navigator.clipboard
      .writeText(customerId)
      .then(() => {
        toast.success(`Customer ID: ${customerId} copied!`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy customer ID. Please try again.", {
          position: "top-center",
        });
      });
  };

  const handleDelete = async (customerId: string) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await api.delete(`/customers/${customerId}`);
        setCustomers((prevCustomers) =>
          prevCustomers.filter((c) => c.id !== customerId)
        );
        toast.success("Customer deleted successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
        });
      } catch (error) {
        console.error("Error deleting customer:", error);
        toast.error("Failed to delete customer. Please try again.", {
          position: "top-center",
        });
      }
    }
  };

  const filteredCustomers = customers.filter((customer) => {
    const terms = searchQuery.toLowerCase().split(" ");
    return terms.every(term =>
      customer.first_name?.toLowerCase().includes(term) ||
      customer.last_name?.toLowerCase().includes(term) ||
      customer.email?.toLowerCase().includes(term)
    );
  });

  if (isLoading) {
    return <p>Loading customers...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Customers</h2>
        <div>
          <button onClick={() => navigate("/customer/create")}>
            Create Customer
          </button>
        </div>
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        <table className="admin-list-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Sex</th>
              <th>Weight (kg)</th>
              <th>Height (cm)</th>
              <th>Birthdate</th>
              <th>Signed up</th>
              <th>Total Workouts</th>
              <th>BSport ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.first_name}</td>
                <td>{customer.last_name}</td>
                <td>{customer.email}</td>
                <td>{customer.sex}</td>
                <td>{customer.weight}</td>
                <td>{customer.height}</td>
                <td>
                  {customer.birthdate &&
                    new Date(customer.birthdate).toLocaleDateString("de-DE", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                </td>
                <td>
                  {customer.created_at &&
                    new Date(customer.created_at).toLocaleString("de-DE", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                </td>
                <td>{customer.total_workouts}</td>
                <td>{customer.bsport_id}</td>
                <td>
                  <div className="actions">
                    <Link to={`/customer/${customer.id}`}>
                      <button>
                        <FaEye title="View Details" />
                      </button>
                    </Link>
                    <Link to={`/customer/${customer.id}/edit`}>
                      <button>
                        <FaPencilAlt title="Edit Customer" />
                      </button>
                    </Link>
                    <button onClick={() => handleCopy(customer.id)}>
                      <FaCopy title="Copy ID" />
                    </button>
                    <button onClick={() => handleDelete(customer.id)}>
                      <FaTrash title="Delete Customer" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </Layout>
  );
}

export default CustomerPage;
