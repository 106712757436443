import React, { useState, useEffect, useCallback } from "react";
import api from './axiosConfig';
import "./AdminList.css"; // Import the new CSS file
import { Link, useNavigate } from "react-router-dom"; // Make sure Link is imported
import { FaEye, FaPencilAlt, FaCopy, FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import { startOfWeek, endOfWeek, addWeeks, subWeeks, isSameWeek } from 'date-fns'; // Import date-fns functions

// Define the Workout type
interface Workout {
  id: string;
  start_datetime: string;
  coach_first_name: string;
  coach_last_name: string;
  location_name: string;
  timer_name: string;
  location_id: string;
  coach_name?: string; // Optional, as it will be added later
  bsport_id?: string; // Add bsport_id to the Workout type
  is_cancelled?: boolean; // Add is_cancelled to the Workout type
}

function WorkoutPage() {
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [showCancelled, setShowCancelled] = useState(false);
  const navigate = useNavigate(); // Get the navigate function
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentWeek, setCurrentWeek] = useState(new Date());

  const handleCopy = (workoutId: string) => {
    navigator.clipboard.writeText(workoutId)
      .then(() => {
        toast.success(`Workout ID: ${workoutId} copied!`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy workout ID. Please try again.", {
          position: "top-center",
        });
      });
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  const handlePreviousWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
  };

  const fetchWorkouts = useCallback(async () => {
    setIsLoading(true);
    const startOfWeekDate = startOfWeek(currentWeek, { weekStartsOn: 1 }).toISOString();
    const endOfWeekDate = endOfWeek(currentWeek, { weekStartsOn: 1 }).toISOString();

    try {
      const response = await api.get<Workout[]>("/workouts", {
        params: {
          start_after: startOfWeekDate,
          start_before: endOfWeekDate,
          is_cancelled: showCancelled ? undefined : false
        }
      });

      const workoutsWithCoachNames = response.data.map((workout) => ({
        ...workout,
        coach_name: `${workout.coach_first_name} ${workout.coach_last_name}`,
      }));

      setWorkouts(workoutsWithCoachNames);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch workouts and related data");
    } finally {
      setIsLoading(false);
    }
  }, [currentWeek, showCancelled]);

  useEffect(() => {
    fetchWorkouts();
  }, [fetchWorkouts]);

  const toggleShowCancelled = () => {
    setShowCancelled(!showCancelled);
  };

  const filteredWorkouts = workouts.filter((workout) => {
    const terms = searchQuery.toLowerCase().split(" ");
    return terms.every(term =>
        (workout.coach_first_name || "").toLowerCase().includes(term) ||
        (workout.coach_last_name || "").toLowerCase().includes(term) ||
        (workout.location_name || "").toLowerCase().includes(term) ||
        (workout.timer_name || "").toLowerCase().includes(term) ||
        workout.bsport_id?.toString().includes(term) ||
        new Date(workout.start_datetime).toLocaleString().toLowerCase().includes(term)
    );
  });

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Workouts</h2>
        <div>
          <button onClick={() => navigate('/workout/create')}>
            Create Workout
          </button>
        </div>
        <div className="week-navigation">
          <button onClick={handlePreviousWeek}><FaChevronLeft /></button>
          <span style={{ margin: '0 10px' }}>
            {`${startOfWeek(currentWeek, { weekStartsOn: 1 }).toLocaleDateString('en-GB', { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit' })} - ${endOfWeek(currentWeek, { weekStartsOn: 1 }).toLocaleDateString('en-GB', { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit' })}`}
          </span>
          <button onClick={handleNextWeek}><FaChevronRight /></button>
        </div>
        <div className="toggle-container">
          <label className="toggle">
            <input
              type="checkbox"
              checked={showCancelled}
              onChange={toggleShowCancelled}
            />
            <span className="slider"></span>
          </label>
          <span>Show Cancelled</span>
        </div>
        <input
          type="text"
          placeholder="Search by start date, coach, location, timer, or bsport ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <table className="admin-list-table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>Coach</th>
              <th>Location</th>
              <th>Timer</th>
              <th>Bsport ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredWorkouts.map((workout) => (
              <tr key={workout.id} style={{ backgroundColor: workout.is_cancelled ? 'lightcoral' : 'transparent' }}>
                <td>{new Date(workout.start_datetime).toLocaleString()}</td>
                <td>{workout.coach_first_name} {workout.coach_last_name}</td>
                <td>{workout.location_name}</td>
                <td>{workout.timer_name}</td>
                <td>{workout.bsport_id}</td> {/* Display Bsport ID */}
                <td>
                  <div className="actions">
                    <Link to={`/workout/${workout.id}`}>
                      <button><FaEye title="View Details" /></button>
                    </Link>
                    <Link to={`/workout/${workout.id}/edit`}>
                      <button><FaPencilAlt title="Edit Workout" /></button>
                    </Link>
                    <button onClick={() => handleCopy(workout.id)}>
                      <FaCopy title="Copy ID" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </Layout>
  );
}

export default WorkoutPage;
