import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import "./AdminList.css"; // Re-use the same CSS file for similar styling
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import Layout from "./Layout";

// Define a type for the location object
interface Location {
  id: number;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  max_spots: number;
  bsport_id: string; // Add bsport_id to the type
}

function LocationPage() {
  const [locations, setLocations] = useState<Location[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    const fetchLocations = async () => {
      setIsLoading(true);
      try {
        const response = await api.get<Location[]>("/locations");
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setError("Failed to fetch locations");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocations();
  }, []);

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      try {
        await api.delete(`/locations/${id}`);
        setLocations(locations.filter(location => location.id !== id)); // Update the state to remove the deleted location
      } catch (error) {
        console.error("Error deleting location:", error);
        setError("Failed to delete location");
      }
    }
  };

  const refreshLocations = async () => {
    try {
      await api.post("/bsport/refresh_locations");
      // Re-fetch locations after refreshing
      const response = await api.get("/locations");
      setLocations(response.data); // Update the state with the new locations
    } catch (error) {
      console.error("Error refreshing locations:", error);
      setError("Failed to refresh locations");
    }
  };

  if (isLoading) {
    return <p>Loading locations...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Locations</h2>
        <div className="horizontal-buttons"> {/* Apply horizontal-buttons class */}
          <button onClick={() => navigate("/location/create")}>
            Create Location
          </button>
          <button onClick={refreshLocations}>Refresh Locations</button>
        </div>

        <table className="admin-list-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Zip Code</th>
              <th>City</th>
              <th>Max Spots</th>
              <th>Bsport ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location) => (
              <tr key={location.id}>
                <td>{location.name}</td>
                <td>{location.address}</td>
                <td>{location.zip_code}</td>
                <td>{location.city}</td>
                <td>{location.max_spots}</td>
                <td>{location.bsport_id}</td>
                <td>
                  <div className="actions">
                    <Link to={`/location/${location.id}`}>
                      <button>
                        <FaEye title="View Details" />
                      </button>
                    </Link>
                    <Link to={`/location/${location.id}/edit`}>
                      <button>
                        <FaPencilAlt title="Edit Location" />
                      </button>
                    </Link>
                    <button onClick={() => handleDelete(location.id)}>
                      <FaTrashAlt title="Delete Location" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default LocationPage;
