import React, { useState, ChangeEvent, FormEvent } from "react";
import api from './axiosConfig';
import { useNavigate } from "react-router-dom";
import "./AdminForm.css";
import Layout from "./Layout";

interface Customer {
  first_name: string;
  last_name: string;
  sex: string;
  birthdate: string;
  weight: string;
  height: string;
  email: string;
}

const CustomerCreate: React.FC = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Customer>({
    first_name: "",
    last_name: "",
    sex: "",
    birthdate: "",
    weight: "",
    height: "",
    email: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const sexes = ["male", "female", "other"];

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setCustomer((prevCustomer) => ({ ...prevCustomer, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await api.post("/users", customer);
      navigate("/customers");
    } catch (err) {
      console.error("Error creating user:", err);
      setError("Failed to create user");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Create New Customer</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="first_name">First Name:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={customer.first_name}
                      onChange={handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="last_name">Last Name:</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={customer.last_name}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label htmlFor="email">Email:</label></td>
                  <td>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={customer.email}
                      onChange={handleChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="sex">Sex:</label>
                  </td>
                  <td>
                    <select
                      id="sex"
                      name="sex"
                      value={customer.sex}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        Select a Sex
                      </option>
                      {sexes.map((sex) => (
                        <option key={sex} value={sex}>
                          {sex}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="birthdate">Birthdate:</label>
                  </td>
                  <td>
                    <input
                      type="date"
                      id="birthdate"
                      name="birthdate"
                      value={customer.birthdate}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label htmlFor="weight">Weight (kg):</label></td>
                  <td>
                    <input
                      type="number"
                      id="weight"
                      name="weight"
                      value={customer.weight}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label htmlFor="height">Height (cm):</label></td>
                  <td>
                    <input
                      type="number"
                      id="height"
                      name="height"
                      value={customer.height}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {error && <p className="error-message">{error}</p>}
            <div>
              <button type="submit" disabled={isLoading}>
                Create Customer
              </button>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
}

export default CustomerCreate;
