import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import { useParams } from "react-router-dom";
import { DropdownProps, Dropdown, Modal, Button } from "semantic-ui-react";
import { FaCheck, FaTimes, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import Layout from "./Layout";
import "./AdminDetails.css";
import "./AdminForm.css";
import "./AdminList.css";
import moment from "moment-timezone";

// Define types for your state variables
interface Exercise {
  id: string;
  name: string;
  muscle_group: string;
}

interface WodExercise {
  wod_id: string;
  id: string;
  set_type: string;
  exercise_id: string;
  exercise_number: number;
  reps: number;
  per_side: boolean;
}

interface Wod {
  id: string;
  date: string;
  timer_id?: string;
  timer_name?: string;
}

interface Timer {
  id: string;
  name: string;
}

interface ValidWod {
  id: string;
  date: string;
}

function WodDetails() {
  const { id } = useParams<{ id: string }>();
  const [wod, setWod] = useState<Wod | null>(null);
  const [timers, setTimers] = useState<Timer[]>([]);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [wodExercises, setWodExercises] = useState<WodExercise[]>([]);
  const [newExercise, setNewExercise] = useState<WodExercise>({
    wod_id: "",
    id: "",
    set_type: "",
    exercise_id: "",
    exercise_number: 0,
    reps: 0, 
    per_side: false,
  });
  const [editingExerciseId, setEditingExerciseId] = useState<string | null>(null);
  const [editedExercise, setEditedExercise] = useState<Partial<WodExercise>>({});
  const [error, setError] = useState<string | null>(null);
  const [isEditingTimer, setIsEditingTimer] = useState(false);
  const [validWods, setValidWods] = useState<ValidWod[]>([]);
  const [selectedWodDate, setSelectedWodDate] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define rep options
  const repOptions = [1, 4, 6, 8, 12].map((rep) => ({
    key: rep,
    text: rep.toString(),
    value: rep,
  }));

  useEffect(() => {
    fetchWod();
    fetchTimers();
    fetchExercises();
    fetchWodExercises();
    fetchValidWods();
  }, []);

  const fetchWod = async () => {
    try {
      const response = await api.get<Wod>(`/wods/${id}`);
      const wodData = response.data;

      // Adjust the date to the desired time zone
      const adjustedDate = moment(wodData.date).tz('Europe/Berlin').format('YYYY-MM-DD');
      setWod({ ...wodData, date: adjustedDate });
    } catch (error) {
      console.error("Error fetching WOD:", error);
      setError("Failed to fetch WOD");
    }
  };

  const fetchTimers = async () => {
    try {
      const response = await api.get<Timer[]>("/timers");
      setTimers(response.data);
    } catch (error) {
      console.error("Error fetching timers:", error);
    }
  };

  const fetchExercises = async () => {
    try {
      const response = await api.get<Exercise[]>("/exercises?fields=id,name,muscle_group");
      setExercises(response.data);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const fetchWodExercises = async () => {
    try {
      const response = await api.get<WodExercise[]>(`/wod_exercises?wod_id=${id}`);
      
      // Sort the exercises by set_type and then by exercise_number
      const sortedExercises = response.data.sort((a, b) => {
        if (a.set_type === b.set_type) {
          return a.exercise_number - b.exercise_number;
        }
        return a.set_type.localeCompare(b.set_type);
      });

      setWodExercises(sortedExercises);
    } catch (error) {
      console.error("Error fetching WOD exercises:", error);
    }
  };

  const fetchValidWods = async () => {
    try {
      const response = await api.get("/wods", { params: { is_valid: true } });
      const adjustedWods = response.data
        .map((wod: Wod) => ({
          ...wod,
          date: moment(wod.date).tz('Europe/Berlin').format('dddd, DD.MM.YYYY')
        }))
        .filter((wod: Wod) => moment(wod.date, 'dddd, DD.MM.YYYY').day() === 1);

      adjustedWods.sort((a: Wod, b: Wod) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setValidWods(adjustedWods);
    } catch (error) {
      console.error("Error fetching valid WODs:", error);
    }
  };

  const handleTimerChange = async (event: React.SyntheticEvent, data: DropdownProps): Promise<void> => {
    const { value } = data;
    if (typeof value === 'string') {
      try {
        await api.put(`/wods/${id}`, { ...wod, timer_id: value });
        setWod((prevWod) => prevWod ? { ...prevWod, timer_id: value } : null);
        setIsEditingTimer(false);
        fetchWod(); // Refetch the WOD to get the updated timer
      } catch (error) {
        console.error("Error updating timer:", error);
        setError("Failed to update timer");
      }
    }
  };

  const handleAddExercise = async () => {
    try {
      console.log("Submitting exercise:", newExercise);
      if (
        !newExercise.exercise_id ||
        !newExercise.set_type ||
        !newExercise.exercise_number ||
        !newExercise.reps
      ) {
        setError("Please fill in all fields.");
        return;
      }

      const { wod_id, ...exerciseWithoutWodId } = newExercise;
      const response = await api.post<WodExercise>("/wod_exercises", {
        wod_id: id,
        ...exerciseWithoutWodId,
      });
      setWodExercises((prevExercises) => [...prevExercises, response.data]);
      setNewExercise({
        wod_id: "",
        id: "",
        set_type: "",
        exercise_id: "",
        exercise_number: 0,
        reps: 0,
        per_side: false,
      });
      fetchWodExercises();
    } catch (error) {
      console.error("Error adding exercise:", error);
      setError("Failed to add exercise");
    }
  };

  const handleExerciseInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof WodExercise, value?: string | number | boolean) => {
    const newValue = value !== undefined ? value : e.target.value;
    setEditedExercise((prevExercise) => {
      const updatedExercise = {
        ...prevExercise,
        [field]: newValue,
      };
      console.log("Updated exercise:", updatedExercise);
      return updatedExercise;
    });
  };

  const handleSaveExercise = async (id: string) => {
    try {
      await api.put(`/wod_exercises/${id}`, editedExercise);
      setEditingExerciseId(null);
      fetchWodExercises();
    } catch (error) {
      console.error("Error updating exercise:", error);
      setError("Failed to update exercise");
    }
  };

  const handleDeleteExercise = async (id: string) => {
    try {
      await api.delete(`/wod_exercises/${id}`);
      setWodExercises((prevExercises) =>
        prevExercises.filter((exercise) => exercise.id !== id)
      );
    } catch (error) {
      console.error("Error deleting exercise:", error);
      setError("Failed to delete exercise");
    }
  };

  const handleNewExerciseChange = (e: React.SyntheticEvent, name: keyof WodExercise, value: string | number | boolean) => {
    setNewExercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  const handlePerSideToggleChange = (e: React.ChangeEvent<HTMLInputElement>, isEditing: boolean = false) => {
    const isChecked = e.target.checked;
    console.log("Toggle changed:", isChecked);
    if (isEditing) {
        setEditedExercise((prevExercise) => ({
            ...prevExercise,
            per_side: isChecked,
        }));
    } else {
        setNewExercise((prevExercise) => ({
            ...prevExercise,
            per_side: isChecked,
        }));
    }
  };

  const handleCopyExercises = async () => {
    try {
      // Fetch exercises from the selected WOD date
      const response = await api.get<WodExercise[]>(`/wod_exercises?wod_id=${selectedWodDate}`);
      const exercisesToCopy = response.data;

      // Delete current WOD exercises if any exist
      if (wodExercises.length > 0) {
        await api.delete(`/wod_exercises?wod_id=${id}`);
      }

      const current_wod_id = id;
      // Add new exercises
      for (const exercise of exercisesToCopy) {
        await api.post("/wod_exercises", {
          wod_id: current_wod_id,
          exercise_id: exercise.exercise_id,
          set_type: exercise.set_type,
          exercise_number: exercise.exercise_number,
          reps: exercise.reps,
          per_side: exercise.per_side,
        });
      }

      fetchWodExercises(); // Refresh the list of exercises
    } catch (error) {
      console.error("Error copying exercises:", error);
    }
  };

  const renderPerSideToggle = (exercise: WodExercise) => {
    if (editingExerciseId === exercise.id) {
      return (
        <label className="toggle">
          <input
            type="checkbox"
            checked={editedExercise.per_side || false}
            onChange={(e) => handlePerSideToggleChange(e, true)}
          />
          <span className="slider"></span>
        </label>
      );
    } else {
      return <span>{exercise.per_side ? "Yes" : "No"}</span>;
    }
  };

  if (!wod) {
    return <p>Loading...</p>;
  }

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Workout of the Day Details</h2>
        <div>
          <h3>{moment(wod.date).format('dddd, DD.MM.YYYY')}</h3>
          <div className="info-element">
            Timer:
            {isEditingTimer ? (
              <Dropdown
                placeholder="Select Timer"
                fluid
                selection
                options={timers.map((timer) => ({
                  key: timer.id,
                  text: timer.name,
                  value: timer.id,
                }))}
                value={wod.timer_id}
                onChange={handleTimerChange}
              />
            ) : (
              <span>{wod.timer_name}</span>
            )}
            <button onClick={() => setIsEditingTimer(!isEditingTimer)}>
              {isEditingTimer ? <FaTimes /> : <FaPencilAlt />}
            </button>
          </div>
        </div>

        <h3>Copy Exercises from Other WOD</h3>
        <div className="add-element-form">
          <Dropdown
            placeholder="Select WOD Date"
            search
            selection
            options={validWods.map((valid_wod) => ({
              key: valid_wod.id,
              text: valid_wod.date,
              value: valid_wod.id,
            }))}
            onChange={(e, { value }) => setSelectedWodDate(value as string)}
          />
          <button onClick={() => setIsModalOpen(true)}>Confirm and overwrite?</button>
        </div>

        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="small"
          dimmer={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5) !important' } }} // Reduced opacity
        >
          <Modal.Header>Confirm Overwrite</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to replace the WOD exercises below with the exercises from {validWods.find(wod => wod.id === selectedWodDate)?.date}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setIsModalOpen(false)}>No</Button>
            <Button positive onClick={() => {
              handleCopyExercises();
              setIsModalOpen(false);
            }}>Yes</Button>
          </Modal.Actions>
        </Modal>

        <h3>Add WOD Exercise</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddExercise();
          }}
        >
          <div className="add-element-form">
            <select
              name="set_type"
              value={newExercise.set_type}
              onChange={(e) =>
                handleNewExerciseChange(e, e.target.name as keyof WodExercise, e.target.value)
              }
              required
            >
              <option value="">Select Set Type</option>
              <option value="warmup">Warm-up</option>
              <option value="main set 1">Main Set 1</option>
              <option value="main set 2">Main Set 2</option>
              <option value="main set 3">Main Set 3</option>
              <option value="main set 4">Main Set 4</option>
              <option value="challenge">Challenge</option>
              <option value="stretching">Stretching</option>
            </select>

            <Dropdown
              placeholder="Select Exercise"
              search
              selection
              options={exercises.map((exercise) => ({
                key: exercise.id,
                text: exercise.name,
                value: exercise.id,
              }))}
              value={editedExercise.exercise_id}
              onChange={(e, data) =>
                handleNewExerciseChange(e, "exercise_id", data.value as string)
              }
            />

            <input
              type="number"
              name="exercise_number"
              value={newExercise.exercise_number}
              onChange={(e) =>
                handleNewExerciseChange(e, e.target.name as keyof WodExercise, parseInt(e.target.value))
              }
              placeholder="Exercise Number"
              required
            />
            <Dropdown
              placeholder="Select Reps"
              name="reps"
              value={newExercise.reps}
              selection
              options={repOptions}
              onChange={(e, data) =>
                handleNewExerciseChange(e, "reps", data.value as number)
              } // Explicitly set name and value
              required
            />
            <div>
              <label>
                Per Side:
                <label className="toggle">
                  <input
                    type="checkbox"
                    checked={newExercise.per_side}
                    onChange={(e) => handlePerSideToggleChange(e)}
                  />
                  <span className="slider"></span>
                </label>
              </label>
            </div>
            <button type="submit">Add Exercise</button>
          </div>
        </form>

        <h3>WOD Exercises</h3>
        {wodExercises.length > 0 ? (
          <table className="admin-list-table">
            <thead>
              <tr>
                <th>Set Type</th>
                <th>Exercise Number</th>
                <th>Exercise Name</th>
                <th>Muscle Group</th>
                <th>Reps</th>
                <th>Per Side</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {wodExercises.map((exercise) => (
                <tr key={exercise.id}>
                  {editingExerciseId === exercise.id ? (
                    <>
                      <td>
                        <select
                          value={editedExercise.set_type}
                          onChange={(e) =>
                            handleExerciseInputChange(e, "set_type")
                          }
                        >
                          <option value="">Select Set Type</option>
                          <option value="warmup">Warm-up</option>
                          <option value="main set 1">Main Set 1</option>
                          <option value="main set 2">Main Set 2</option>
                          <option value="main set 3">Main Set 3</option>
                          <option value="main set 4">Main Set 4</option>
                          <option value="challenge">Challenge</option>
                          <option value="stretching">Stretching</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedExercise.exercise_number}
                          onChange={(e) =>
                            handleExerciseInputChange(e, "exercise_number")
                          }
                        />
                      </td>
                      <td>
                        <Dropdown
                          placeholder="Select Exercise"
                          fluid
                          search
                          selection
                          options={exercises.map((exercise) => ({
                            key: exercise.id,
                            text: exercise.name,
                            value: exercise.id,
                          }))}
                          value={editedExercise.exercise_id}
                          onChange={(e, data) =>
                            handleExerciseInputChange(
                              e as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
                              "exercise_id",
                              data.value as string
                            )
                          }
                        />
                      </td>
                      <td>
                        {exercises.find(
                          (ex) => ex.id === editedExercise.exercise_id
                        )?.muscle_group || "N/A"}
                      </td>
                      <td>
                        <Dropdown
                          placeholder="Select Reps"
                          fluid
                          selection
                          options={repOptions}
                          value={editedExercise.reps}
                          onChange={(e, data) =>
                            handleExerciseInputChange(e as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, "reps", data.value as number)
                          }
                        />
                      </td>
                      <td>
                        {renderPerSideToggle(exercise)}
                      </td>
                      <td>
                        <div className="actions">
                        <button
                          onClick={() => handleSaveExercise(exercise.id)}>
                          <FaCheck title="Save Exercise" />
                        </button>
                        <button
                          onClick={() => setEditingExerciseId(null)}>
                          <FaTimes title="Cancel Edit" />
                        </button>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{exercise.set_type}</td>
                      <td>{exercise.exercise_number}</td>
                      <td>
                        {exercises.find((ex) => ex.id === exercise.exercise_id)
                          ?.name || "N/A"}
                      </td>
                      <td>
                        {exercises.find((ex) => ex.id === exercise.exercise_id)
                          ?.muscle_group || "N/A"}
                      </td>
                      <td>{exercise.reps}</td>
                      <td>{renderPerSideToggle(exercise)}</td>
                      <td>
                        <div className="actions">
                        <button
                          onClick={() => {
                            setEditingExerciseId(exercise.id);
                            setEditedExercise(exercise);
                          }}>
                          <FaPencilAlt title="Edit Exercise" />
                        </button>
                        <button
                          onClick={() => handleDeleteExercise(exercise.id)}>
                          <FaTrashAlt title="Delete Exercise" />
                        </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No exercises added yet.</p>
        )}

        {error && <p className="error-message">{error}</p>}
      </div>
    </Layout>
  );
}

export default WodDetails;