import React, { useState, useEffect, ChangeEvent } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import api from './axiosConfig';
import "./LocationDetails.css"; // Ensure this file contains the necessary styles
import {
  FaEdit,
  FaTrashAlt,
  FaPencilAlt,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import Layout from "./Layout";

// Define interfaces for the data structures
interface Location {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  google_maps_link: string;
  address: string;
  zip_code: string;
  city: string;
  max_spots: number;
  floorplan_url: string | null;
  zone_id: string;
}

interface Timer {
  id: string;
  name: string;
}

interface WorkoutStructure {
  id: string;
  timer_id: string;
  structure: string;
  timer_name: string;
}

function LocationDetails() {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const [location, setLocation] = useState<Location | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [workoutStructures, setWorkoutStructures] = useState<WorkoutStructure[]>([]);
  const [timers, setTimers] = useState<Timer[]>([]);
  const [newStructure, setNewStructure] = useState<{ timer_id: string; structure: string }>({
    timer_id: "",
    structure: "",
  });
  const [editingStructureId, setEditingStructureId] = useState<string | null>(null);
  const [editedStructure, setEditedStructure] = useState<{ [key: string]: string }>({});
  const [editingStructures, setEditingStructures] = useState<{ [key: string]: boolean }>({});
  const [isLoadingTimers, setIsLoadingTimers] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const locationResponse = await api.get(`/locations/${locationId}`);
        setLocation(locationResponse.data);

        // Fetch existing workout structures for this location
        const structuresResponse = await api.get(
          `/workout_structures?location_id=${locationId}`
        );
        setWorkoutStructures(structuresResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch location data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [locationId]); // Fetch when locationId changes

  const handleNewStructureChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setNewStructure((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const fetchAvailableTimers = async () => {
      setIsLoadingTimers(true);
      try {
        const allTimersResponse = await api.get("/timers?fields=id,name");
        const availableTimers = allTimersResponse.data.filter(
          (timer: Timer) =>
            !workoutStructures.some((ws) => ws.timer_id === timer.id)
        );
        setTimers(availableTimers); // Update the timers state
      } catch (err) {
        console.error("Error fetching timers:", err);
        // Handle the error appropriately
      } finally {
        setIsLoadingTimers(false); // Set isLoadingTimers to false after fetching
      }
    };

    fetchAvailableTimers();
  }, [workoutStructures]);


  const handleAddStructure = async () => {
    try {
      await api.post("/workout_structures", {
        location_id: locationId,
        ...newStructure,
      });
      // Re-fetch workout structures to update the table
      const structuresResponse = await api.get(
        `/workout_structures?location_id=${locationId}`
      );
      setWorkoutStructures(structuresResponse.data);
      setNewStructure({ timer_id: "", structure: "" }); // Clear the form
    } catch (err) {
      console.error("Error adding workout structure:", err);
      // Handle the error appropriately (e.g., display an error message)
    }
  };

  // Helper function to validate JSON
  const isValidJson = (jsonString: string) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleStructureEdit = (id: string) => {
    setEditingStructureId(id);
    setEditingStructures((prev) => ({ ...prev, [id]: true })); // Mark the structure as being edited
  };

  const handleCancelEdit = (id: string) => {
    setEditingStructureId(null);
    setEditingStructures((prev) => {
      const newState = { ...prev };
      delete newState[id]; // Remove the entry for the canceled edit
      return newState;
    });
    setEditedStructure((prev) => {
      const newState = { ...prev };
      delete newState[id]; // Remove the entry for the canceled edit
      return newState;
    });
  };

  const handleStructureInputChange = (event: ChangeEvent<HTMLTextAreaElement>, id: string) => {
    setEditedStructure((prev) => ({ ...prev, [id]: event.target.value }));
  };

  const handleUpdateStructure = async (id: string) => {
    try {
      // 1. Check if any changes have been made
      const foundStructure = workoutStructures.find(ws => ws.id === id);
      if (foundStructure && JSON.stringify(editedStructure[id]) === JSON.stringify(foundStructure.structure)) {
        // No changes, treat it like canceling the edit
        handleCancelEdit(id);
        return; // Exit the function early
      }

      // 2. If there are changes, proceed with the update
      await api.put(`/workout_structures/${id}`, { structure: JSON.stringify(editedStructure[id]) });

      setEditingStructureId(null);
      setEditingStructures(prev => ({ ...prev, [id]: false })); // Disable edit mode
      
      // 3. Re-fetch workout structures to update the table
      const structuresResponse = await api.get(`/workout_structures?location_id=${locationId}`);
      setWorkoutStructures(structuresResponse.data);
    } catch (error) {
      console.error("Error updating workout structure:", error);
      // Handle the error appropriately (e.g., display an error message)
    }
  };

  const handleDeleteStructure = async (structureId: string) => {
    try {
      await api.delete(`/workout_structures/${structureId}`);
      // Re-fetch workout structures
      const structuresResponse = await api.get(
        `/workout_structures?location_id=${locationId}`
      );
      setWorkoutStructures(structuresResponse.data);
    } catch (err) {
      console.error("Error deleting workout structure:", err);
      // Handle the error appropriately
    }
  };

  const handleSpotClick = (spot: string) => {
    navigate(`/location/${locationId}/spot/${spot}`);
  };

  if (isLoading || isLoadingTimers) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!location) {
    return <p>Location not found.</p>;
  }

  return (
    <Layout>
      <div className="location-details-container">
        <h2>Location Details</h2>
        <table className="info-table">
          <tbody>
            <tr>
              <td>
                <label>Name:</label>
              </td>
              <td>{location.name}</td>
            </tr>
            <tr>
              <td>
                <label>Latitude:</label>
              </td>
              <td>{location.latitude}</td>
            </tr>
            <tr>
              <td>
                <label>Longitude:</label>
              </td>
              <td>{location.longitude}</td>
            </tr>
            <tr>
              <td>
                <label>Google Maps Link:</label>
              </td>
              <td>
                <Link
                  to={location.google_maps_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Open Google Maps</button>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <label>Address:</label>
              </td>
              <td>{location.address}</td>
            </tr>
            <tr>
              <td>
                <label>Zip Code:</label>
              </td>
              <td>{location.zip_code}</td>
            </tr>
            <tr>
              <td>
                <label>City:</label>
              </td>
              <td>{location.city}</td>
            </tr>
            <tr>
              <td>
                <label>Max Spots:</label>
              </td>
              <td>{location.max_spots}</td>
            </tr>
            <tr>
              <td>
                <label>Floorplan:</label>
              </td>
              <td>
                {location.floorplan_url ? (
                  <a href={location.floorplan_url} target="_blank" rel="noopener noreferrer">
                    View Floorplan
                  </a>
                ) : (
                  "No floorplan available"
                )}
              </td>
            </tr>
            <tr>
              <td>
                <label>Zone ID:</label>
              </td>
              <td>{location.zone_id}</td>
            </tr>
          </tbody>
        </table>

        <div>
          <Link to={`/location/${location.id}/edit`}>
            <button>
              <FaEdit title="Edit Location" />
            </button>
          </Link>
        </div>

          {/* Players Grid */}
        <h3>Players</h3>
        <div className="players-button-container">
          <div className="main-players-container">
            <button onClick={() => navigate(`/location/${locationId}/play`)}>
              Workout Player
            </button>
            <button onClick={() => navigate(`/location/${locationId}/workouts`)}>
              Coach View
            </button>
            <button onClick={() => navigate(`/location/${locationId}/customers/1`)}>
              Customer Sign Up 1
            </button>
            <button onClick={() => navigate(`/location/${locationId}/customers/2`)}>
              Customer Sign Up 2
            </button>
            <button onClick={() => navigate(`/location/${locationId}/customers/3`)}>
              Customer Sign Up 3
            </button>
            <button onClick={() => navigate(`/location/${locationId}/weights/1`)}>
              Weights 1
            </button>
            <button onClick={() => navigate(`/location/${locationId}/weights/2`)}>
              Weights 2
            </button>
            <button onClick={() => navigate(`/location/${locationId}/weights/3`)}>
              Weights 3
            </button>
          </div>
          <div className="players-grid">
            {Array.from({ length: location.max_spots }, (_, index) => {
              const prefix = String.fromCharCode(65 + (index % 6));
              const number = Math.floor(index / 6) + 1;
              return (
                <button
                  key={prefix + number}
                  onClick={() => handleSpotClick(prefix + number)}
                >
                  {prefix + number}
                </button>
              );
            })}
          </div>
        </div>

      {/* Workout Structures Section */}
      <h3>Workout Structures</h3>

      {/* Add Structure Form */}
      {!isLoadingTimers && (
      <form
        className="add-structure-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleAddStructure();
        }}
      >
        <select
          name="timer_id"
          value={newStructure.timer_id}
          onChange={handleNewStructureChange}
          required
        >
          <option value="">Select a Timer</option>
          {timers.map((timer) => (
            <option key={timer.id} value={timer.id}>
              {timer.name}
            </option>
          ))}
        </select>
        <textarea
          name="structure"
          value={newStructure.structure}
          onChange={handleNewStructureChange}
          placeholder="Enter JSON structure"
        />
        <button type="submit">Add Structure</button>
      </form>
      )}

      {/* Existing Structures Table */}
      {workoutStructures.length > 0 ? ( // Only show the table if there are structures
        <table className="admin-list-table">
          <thead>
            <tr>
              <th>Timer</th>
              <th>Structure (JSON)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {workoutStructures.map((ws) => (
              <tr key={ws.id}>
                <td>{ws.timer_name || "Timer Not Found"}</td>
                <td>
                  {!editingStructures[ws.id] ? ( // Read-only view if not editing
                    <pre>{JSON.stringify(ws.structure, null, 2)}</pre>
                  ) : (
                    <textarea
                      value={
                        editedStructure[ws.id] ||
                        JSON.stringify(ws.structure, null, 2)
                      } // Use editedStructure if available
                      onChange={(e) => handleStructureInputChange(e, ws.id)}
                    />
                  )}
                </td>
                <td>
                  {editingStructureId === ws.id ? ( // Show "Update" button if editing
                    <>
                      <button
                        onClick={() => handleUpdateStructure(ws.id)}
                        disabled={!isValidJson(editedStructure[ws.id])}
                      >
                        <FaCheck title="Update Structure" />
                      </button>
                      <button onClick={() => handleCancelEdit(ws.id)}>
                        <FaTimes title="Cancel Edit" />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => handleStructureEdit(ws.id)}
                    >
                      <FaPencilAlt title="Edit Structure" />
                    </button>
                  )}

                  <button onClick={() => handleDeleteStructure(ws.id)}>
                    <FaTrashAlt title="Delete Structure" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No workout structures yet.</p>
      )}
    </div>
    </Layout>
  );
}

export default LocationDetails;