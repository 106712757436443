import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// Web App Firebase configuration
const firebaseConfig = {
 apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
 authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
 projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
 storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
 messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
 appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
 measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); 
const auth = getAuth(app);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
