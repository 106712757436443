// src/WorkoutDetails.tsx
import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import api from './axiosConfig';
import { useParams, Link } from "react-router-dom";
import "./AdminDetails.css";
import "./AdminForm.css";
import "./AdminList.css";
import {
  FaEdit,
  FaTrashAlt,
  FaCheck,
  FaTimes,
  FaPencilAlt,
} from "react-icons/fa";
import moment from "moment-timezone";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css';
import Layout from "./Layout";

interface Workout {
  id: string;
  start_datetime: string;
  timer_name: string;
  playlists: Record<string, string>;
  bsport_id?: string; // Add bsport_id to the Workout type
  exercise_count: number;
}

interface Exercise {
  id: string;
  name: string;
  muscle_group: string;
}

interface WorkoutExercise {
  id: string;
  exercise_id: string;
  set_type: string;
  exercise_number: number;
  reps: number;
  per_side: boolean;
  exercise?: Exercise;
}

interface NewExercise {
  set_type: string;
  exercise_id: string;
  exercise_number: number;
  reps: number;
  per_side: boolean;
}

interface SortOrder {
  column: string | null;
  direction: "asc" | "desc" | null;
}

function WorkoutDetails() {
  const { workoutId } = useParams<{ workoutId: string }>();
  const [workout, setWorkout] = useState<Workout | null>(null);
  const [workoutExercises, setWorkoutExercises] = useState<WorkoutExercise[]>([]);
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [newExercise, setNewExercise] = useState<NewExercise>({
    set_type: "",
    exercise_id: "",
    exercise_number: 0,
    reps: 0,
    per_side: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
  const [editingExerciseId, setEditingExerciseId] = useState<string | null>(null);
  const [editedExercise, setEditedExercise] = useState<Partial<WorkoutExercise>>({});
  const [sortOrder, setSortOrder] = useState<SortOrder>({ column: null, direction: null });
  const [selectedExercise, setSelectedExercise] = useState<string | null>(null);

  const repOptions = [1, 4, 6, 8, 12].map((rep) => ({
    key: rep,
    text: rep.toString(),
    value: rep,
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [workoutResponse, exercisesResponse] = await Promise.all([
          api.get<Workout>(`/workouts/${workoutId}`),
          api.get<Exercise[]>("/exercises?fields=id,name,muscle_group"),
        ]);
        setWorkout(workoutResponse.data);
        setExercises(exercisesResponse.data);
        setFilteredExercises(exercisesResponse.data);

        const workoutExercisesResponse = await api.get<WorkoutExercise[]>(
          `/workout_exercises?workout_id=${workoutId}`
        );

        const sortedWorkoutExercises = workoutExercisesResponse.data.sort((a, b) => {
          if (a.set_type === b.set_type) {
            return a.exercise_number - b.exercise_number;
          }
          return a.set_type.localeCompare(b.set_type);
        });

        setWorkoutExercises(sortedWorkoutExercises);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch workout data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [workoutId]);

  const handleExerciseSearch = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setSelectedExercise(data.value as string);
    setNewExercise((prevExercise) => ({
      ...prevExercise,
      exercise_id: data.value as string,
    }));
  };

  const handleAddExercise = async () => {
    try {
      if (
        !newExercise.exercise_id ||
        !newExercise.set_type ||
        !newExercise.exercise_number ||
        !newExercise.reps
      ) {
        setError("Please fill in all fields.");
        return;
      }

      if (newExercise.reps <= 0) {
        setError("Reps must be a positive number.");
        return;
      }

      const isDuplicateExerciseNumber = workoutExercises.some(
        (we) =>
          we.exercise_number === newExercise.exercise_number &&
          we.set_type === newExercise.set_type
      );

      if (isDuplicateExerciseNumber) {
        setError("Exercise number already used for this set type.");
        return;
      }

      const response = await api.post<WorkoutExercise>("/workout_exercises", {
        workout_id: workoutId,
        exercise_id: newExercise.exercise_id,
        set_type: newExercise.set_type,
        exercise_number: newExercise.exercise_number,
        reps: newExercise.reps,
        per_side: newExercise.per_side,
      });

      setWorkoutExercises((prev) => [...prev, response.data]);

      setNewExercise({
        set_type: "",
        exercise_id: "",
        exercise_number: 0,
        reps: 0,
        per_side: false,
      });
      setError(null);
    } catch (err) {
      console.error("Error adding exercise:", err);
      if (err instanceof AxiosError && err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to add exercise.");
      }
    }
  };

  const handleEditExercise = (exercise: WorkoutExercise) => {
    setEditingExerciseId(exercise.id);
    setEditedExercise(exercise);
  };

  const handleSaveExercise = async (id: string) => {
    console.log(editedExercise)
    try {
      if (
        !editedExercise.exercise_id ||
        !editedExercise.set_type ||
        !editedExercise.exercise_number ||
        !editedExercise.reps
      ) {
        setError("Please fill in all fields.");
        return;
      }

      await api.put(`/workout_exercises/${id}`, {
        exercise_id: editedExercise.exercise_id,
        set_type: editedExercise.set_type,
        exercise_number: editedExercise.exercise_number,
        reps: editedExercise.reps,
        per_side: editedExercise.per_side,
      });
      setEditingExerciseId(null);

      const response = await api.get<WorkoutExercise[]>(
        `/workout_exercises?workout_id=${workoutId}`
      );
      setWorkoutExercises(response.data);
    } catch (error) {
      console.error("Error updating workout exercise:", error);
      if (error instanceof AxiosError && error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("Failed to update workout exercise.");
      }
    }
  };

  const handleDeleteExercise = async (id: string) => {
    try {
      await api.delete(`/workout_exercises/${id}`);
      setWorkoutExercises((prevExercises) =>
        prevExercises.filter((we) => we.id !== id)
      );
    } catch (err) {
      console.error("Error deleting workout exercise:", err);
      setError("Failed to delete workout exercise");
    }
  };

  const handleCancelEdit = () => {
    setEditingExerciseId(null);
    setEditedExercise({});
  };

  const handleExerciseInputChange = (
    event: React.SyntheticEvent<HTMLElement>,
    field: keyof WorkoutExercise,
    value?: string | number | boolean
  ) => {
    setEditedExercise((prevExercise) => ({
      ...prevExercise,
      [field]: value,
    }));
  };

  const handleNewExerciseChange = (
    event: React.SyntheticEvent<HTMLElement>,
    name: keyof NewExercise,
    value: string | number | boolean
  ) => {
    setNewExercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  const getSortArrow = (column: string) => {
    if (sortOrder.column === column) {
      return sortOrder.direction === "asc" ? " \u25B2" : " \u25BC";
    } else {
      return "";
    }
  };

  const handleSort = (column: string) => {
    setSortOrder((prevSortOrder) => ({
      column,
      direction:
        prevSortOrder.column === column && prevSortOrder.direction === "asc"
          ? "desc"
          : "asc",
    }));

    setWorkoutExercises((prevExercises) => {
      const sortedExercises = [...prevExercises].sort((a, b) => {
        const valueA = a[column as keyof WorkoutExercise];
        const valueB = b[column as keyof WorkoutExercise];

        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortOrder.direction === "asc"
            ? valueA.localeCompare(valueB, undefined, { sensitivity: "base" })
            : valueB.localeCompare(valueA, undefined, { sensitivity: "base" });
        } else {
          return sortOrder.direction === "asc"
            ? Number(valueA) - Number(valueB)
            : Number(valueB) - Number(valueA);
        }
      });

      return sortedExercises;
    });
  };

  const renderPerSideToggle = (exercise: WorkoutExercise) => {
    if (editingExerciseId === exercise.id) {
      return (
        <label className="toggle">
          <input
            type="checkbox"
            checked={editedExercise.per_side || false}
            onChange={(e) => handleExerciseInputChange(e, "per_side", e.target.checked)}
          />
          <span className="slider"></span>
        </label>
      );
    } else {
      return <span>{exercise.per_side ? "Yes" : "No"}</span>;
    }
  };

  if (!workout || isLoading) {
    return <div>Loading...</div>;
  }

  const renderExerciseNameCell = (workoutExercise: WorkoutExercise) => {
    if (editingExerciseId === workoutExercise.id) {
      return (
        <Dropdown
          placeholder="Select Exercise"
          fluid
          search
          selection
          options={exercises.map((exercise) => ({
            key: exercise.id,
            text: exercise.name,
            value: exercise.id,
          }))}
          value={editedExercise.exercise_id}
          onChange={(e, data) =>
            handleExerciseInputChange(e, "exercise_id", data.value as string)
          }
        />
      );
    } else {
      return (
        <span>{workoutExercise.exercise?.name || "Exercise Not Found"}</span>
      );
    }
  };

  const renderRepsCell = (workoutExercise: WorkoutExercise) => {
    if (editingExerciseId === workoutExercise.id) {
      return (
        <Dropdown
          placeholder="Select Reps"
          fluid
          selection
          options={repOptions}
          value={editedExercise.reps}
          onChange={(e, data) => handleExerciseInputChange(e, "reps", data.value as string)}
        />
      );
    } else {
      return <span>{workoutExercise.reps}</span>;
    }
  };

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Workout Details</h2>
        <table className="info-table">
          <tbody>
            <tr>
              <td>
                <label>Start Date/Time:</label>
              </td>
              <td>
                {workout &&
                  moment
                    .tz(workout.start_datetime, "UTC")
                    .tz(moment.tz.guess())
                    .format("MMMM D, YYYY [at] HH:mm")}
              </td>
            </tr>
            <tr>
              <td>
                <label>Timer:</label>
              </td>
              <td>{workout.timer_name}</td>
            </tr>
            <tr>
              <td>
                <label>Bsport ID:</label> {/* Add Bsport ID display */}
              </td>
              <td>{workout.bsport_id}</td>
            </tr>
            <tr>
              <td>
                <label>Exercise count:</label> {/* Add Bsport ID display */}
              </td>
              <td>{workout.exercise_count}</td>
            </tr>
            <tr>
              <td>
                <label>Playlists:</label>
              </td>
              <td>
                {workout.playlists &&
                  Object.keys(workout.playlists).length > 0 && (
                    <div>
                      <ul>
                        {Object.entries(workout.playlists).map(
                          ([key, value]) =>
                            value && (
                              <li key={key}>
                                <strong>{key}:</strong>{" "}
                                <a
                                  href={value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {value}
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <Link to={`/workout/${workout.id}/edit`}>
            <button>
              <FaEdit title="Edit Exercise" />
            </button>
          </Link>
        </div>
        <h3>Workout Exercises</h3>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddExercise();
          }}
        >
          <div className="add-element-form">
            <div>
              <select
                name="set_type"
                value={newExercise.set_type}
                onChange={(e) =>
                  handleNewExerciseChange(e, e.target.name as keyof NewExercise, e.target.value)
                }
                required
              >
                <option value="">Select Set Type</option>
                <option value="warmup">Warm Up</option>
                <option value="main set 1">Main Set 1</option>
                <option value="main set 2">Main Set 2</option>
                <option value="main set 3">Main Set 3</option>
                <option value="main set 4">Main Set 4</option>
                <option value="main set 5">Main Set 5</option>
                <option value="main set 6">Main Set 6</option>
                <option value="challenge">Challenge</option>
                <option value="stretching">Stretching</option>
                <option value="breathing">Breathing</option>
              </select>
            </div>
            <div>
              <Dropdown
                placeholder="Select Exercise"
                search
                selection
                options={filteredExercises.map((exercise) => ({
                  key: exercise.id,
                  value: exercise.id,
                  text: exercise.name,
                }))}
                onSearchChange={handleExerciseSearch}
                value={selectedExercise ?? ''}
                onChange={handleExerciseSearch}
              />
            </div>
            <div>
              <input
                type="number"
                name="exercise_number"
                value={newExercise.exercise_number}
                onChange={(e) =>
                  handleNewExerciseChange(e, e.target.name as keyof NewExercise, e.target.value)
                }
                placeholder="Exercise Number"
                required
              />
            </div>
            <div>
              <Dropdown
                placeholder="Select Reps"
                name="reps"
                value={newExercise.reps}
                selection
                options={repOptions}
                onChange={(e, data) =>
                  handleNewExerciseChange(e, "reps", data.value as number)
                }
                required
              />
            </div>
            <div>
              <label>
                Per Side:
                <label className="toggle">
                  <input
                    type="checkbox"
                    checked={newExercise.per_side}
                    onChange={(e) => handleNewExerciseChange(e, "per_side", e.target.checked)}
                  />
                  <span className="slider"></span>
                </label>
              </label>
            </div>
            <div>
              <button type="submit" className="button">
                Add Exercise
              </button>
            </div>
          </div>
        </form>

        {error && <p className="error-message">{error}</p>}

        {workoutExercises.length > 0 ? (
          <table className="admin-list-table">
            <thead>
              <tr>
                <th onClick={() => handleSort("set_type")}>
                  Set Type {getSortArrow("set_type")}
                </th>
                <th onClick={() => handleSort("exercise_number")}>
                  Exercise Number {getSortArrow("exercise_number")}
                </th>
                <th onClick={() => handleSort("name")}>
                  Exercise Name {getSortArrow("name")}
                </th>
                <th onClick={() => handleSort("muscle_group")}>
                  Muscle Group {getSortArrow("muscle_group")}
                </th>
                <th onClick={() => handleSort("reps")}>
                  Reps {getSortArrow("reps")}
                </th>
                <th>Per Side</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {workoutExercises.map((we) => (
                <tr key={we.id}>
                  {editingExerciseId === we.id ? (
                    <>
                      <td>
                        <select
                          value={editedExercise.set_type}
                          onChange={(e) =>
                            handleExerciseInputChange(e, "set_type")
                          }
                        >
                          <option value="">Select Set Type</option>
                          <option value="warmup">Warm-up</option>
                          <option value="main set 1">Main Set 1</option>
                          <option value="main set 2">Main Set 2</option>
                          <option value="main set 3">Main Set 3</option>
                          <option value="main set 4">Main Set 4</option>
                          <option value="main set 5">Main Set 5</option>
                          <option value="main set 6">Main Set 6</option>
                          <option value="challenge">Challenge</option>
                          <option value="stretching">Stretching</option>
                          <option value="breathing">Breathing</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={editedExercise.exercise_number}
                          onChange={(e) =>
                            handleExerciseInputChange(e, "exercise_number")
                          }
                        />
                      </td>
                      <td>{renderExerciseNameCell(we)}</td>
                      <td>
                        {
                          exercises.find(
                            (exercise) => exercise.id === we.exercise_id
                          )?.muscle_group
                        }
                      </td>
                      <td>{renderRepsCell(we)}</td>
                      <td>{renderPerSideToggle(we)}</td>
                      <td>
                        <div className="actions">
                          <button onClick={() => handleSaveExercise(we.id)}>
                            <FaCheck title="Save Exercise" />
                          </button>
                          <button onClick={handleCancelEdit} className="button">
                            <FaTimes title="Cancel Edit" />
                          </button>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <p>{we.set_type}</p>
                      </td>
                      <td>
                        <p>{we.exercise_number}</p>
                      </td>
                      <td>
                        <p>
                          {exercises.find(
                            (exercise) => exercise.id === we.exercise_id
                          )?.name || "Exercise not found"}
                        </p>
                      </td>
                      <td>
                        <p>
                          {exercises.find(
                            (exercise) => exercise.id === we.exercise_id
                          )?.muscle_group || "Muscle group not found"}
                        </p>
                      </td>
                      <td>{we.reps}</td>
                      <td>{we.per_side ? "Yes" : "No"}</td>
                      <td>
                        <div className="actions">
                          <button
                            onClick={() => handleEditExercise(we)}
                            className="button"
                          >
                            <FaPencilAlt title="Edit Exercise" />
                          </button>
                          <button
                            onClick={() => handleDeleteExercise(we.id)}
                            className="button"
                          >
                            <FaTrashAlt title="Delete Exercise" />
                          </button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No workout exercises yet.</p>
        )}
      </div>
    </Layout>
  );
}

export default WorkoutDetails;
