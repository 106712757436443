import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import api from './axiosConfig';
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./LocationTablet.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationWeightAdjustment from './LocationWeightAdjustment';
import LocationSpotSelection from './LocationSpotSelection';
import { getNextWeight } from './utils/weightUtils';
import TwoChoiceModal from './TwoChoiceModal';

interface TimerState {
  intervalIndex: number;
  timeLeft: number;
  isRunning: boolean;
}

interface Interval {
  duration: number;
  intervalType: string;
  exercise_number: number;
}

interface TimerData {
  name: string;
  intervals: Interval[];
}

interface WorkoutData {
  coach_first_name: string;
  start_datetime: string;
  location_id: string;
}

interface Booking {
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string | null;
  selected?: boolean;
  has_attended: boolean;
  spot: string | null;
  id: string;
  customer_id: string;
  requires_signup: boolean;
  workout_number: number;
}

interface Exercise {
  exerciseId: string;
  exerciseName: string;
  exerciseShortName: string;
  weight: number;
  reps: number;
  equipment: "Leg Press" | "Cable" | "Dumbbell" | "Barbell" | "Smith Machine";
}

interface AllCustomerWeights {
  [bookingId: string]: Exercise[];
}

const LocationCoachView: React.FC = () => {
  const { workoutId } = useParams<{ workoutId: string }>();
  const { locationId } = useParams<{ locationId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timerState, setTimerState] = useState<TimerState>({
    intervalIndex: 0,
    timeLeft: 0,
    isRunning: false,
  });
  const [timerData, setTimerData] = useState<TimerData | null>(null);
  const [workoutData, setWorkoutData] = useState<WorkoutData | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [ws, setWs] = useState<WebSocket | null>(null);
  const navigate = useNavigate();
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const circleCircumference = 2 * Math.PI * 140;
  const strokeDashArray =
    timerData?.intervals && timerState.timeLeft !== null
      ? `${((timerData.intervals[timerState.intervalIndex]?.duration -
        timerState.timeLeft) /
        (timerData.intervals[timerState.intervalIndex]?.duration)) *
      circleCircumference
      } ${circleCircumference}`
      : `0 ${circleCircumference}`;

  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [isDetailView, setIsDetailView] = useState<boolean>(false);
  const [allCustomerWeights, setAllCustomerWeights] = useState<AllCustomerWeights>({});
  const [isSpotSelectionVisible, setIsSpotSelectionVisible] = useState(false);
  const [floorplanUrl, setFloorplanUrl] = useState<string | null>(null);
  const [maxSpots, setMaxSpots] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalAText, setModalAText] = useState<string>('Yes');
  const [modalBText, setModalBText] = useState<string>('No');
  const [onModalResponse, setOnModalResponse] = useState<(choice: boolean) => void>(() => { });
  const [shouldSendResetMessage, setShouldSendResetMessage] = useState<boolean>(false);


  // Function to fetch bookings and weights
  const fetchBookingsAndWeights = async () => {
    try {
      const bookingsResponse = await api.get(`/bookings`, {
        params: { workout_id: workoutId },
      });
      setBookings(bookingsResponse.data);
      setFilteredBookings(bookingsResponse.data);

      const weightsResponse = await api.get(`/workouts/${workoutId}/weights`);
      const weightsData = weightsResponse.data;

      const groupedWeights: AllCustomerWeights = weightsData.reduce((acc: AllCustomerWeights, item: any) => {
        const { booking_id, exercise_id, exercise_name, exercise_short_name, weight, reps, equipment } = item;
        if (!acc[booking_id]) {
          acc[booking_id] = [];
        }
        acc[booking_id].push({
          exerciseId: exercise_id,
          exerciseName: exercise_name,
          exerciseShortName: exercise_short_name,
          weight: parseFloat(weight),
          reps: parseInt(reps, 10),
          equipment: equipment,
        });
        return acc;
      }, {});

      setAllCustomerWeights(groupedWeights);
    } catch (error) {
      console.error('Error fetching bookings and weights:', error);
    }
  };

  // Function to fetch workout and set timer data
  const fetchAndSetTimer = async () => {
    const workoutResponse = await api.get(`/workouts/${workoutId}`);
    setWorkoutData(workoutResponse.data);
    const timerId = workoutResponse.data.timer_id;
    const timerResponse = await api.get(`/timers/${timerId}`);
    setTimerData(timerResponse.data);
    setTimerState((prevState) => ({
      ...prevState,
      timeLeft: timerResponse.data.intervals[0].duration,
    }));

    // Set the flag to send reset message later
    setShouldSendResetMessage(true);
  };

  const fetchData = async () => {
    try {
      const workoutResponse = await api.get(`/workouts/${workoutId}`);
      setWorkoutData(workoutResponse.data);
      const timerId = workoutResponse.data.timer_id;
      const timerResponse = await api.get(`/timers/${timerId}`);
      setTimerData(timerResponse.data);
      // Fetch bookings and weights
      await fetchBookingsAndWeights();

      const locationResponse = await api.get(`/locations/${locationId}`);
      setFloorplanUrl(locationResponse.data.floorplan_url);
      setMaxSpots(locationResponse.data.max_spots);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set isLoading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, [workoutId]);

  useEffect(() => {
    if (shouldSendResetMessage && ws && ws.readyState === WebSocket.OPEN) {
      sendResetMessage(ws);
      setShouldSendResetMessage(false); // Reset the flag
    }
  }, [shouldSendResetMessage, ws]);

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl =
        process.env.NODE_ENV === "production"
          ? `wss://${new URL(process.env.REACT_APP_API_URL || "").host}`
          : "ws://localhost:5000";

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log("LocationCoachView connected to WebSocket server");
        // Send reset message if the flag is set
        if (shouldSendResetMessage) {
          sendResetMessage(newWs);
          setShouldSendResetMessage(false); // Reset the flag
        }
      };

      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "timerUpdate" && data.locationId === locationId) {
          setTimerState(data.timerState);
        }

        if (data.type === 'resetTimer' && data.locationId === locationId && data.workoutId !== workoutId) {
          navigate(`/location/${data.locationId}/workout/${data.workoutId}/coach`);
        }

        if (data.type === "bookingUpdate" && data.workoutId === workoutId) {
          console.log("Received booking update:", data);
          // Refetch bookings and weights
          fetchBookingsAndWeights();
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        newWs.close();
      };

      setWs(newWs);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [workoutId, timerData]);

  const sendResetMessage = (websocket: WebSocket) => {
    const resetMessage = JSON.stringify({
      type: "reset",
      workoutId,
      locationId,
      timerState,
    });
    websocket.send(resetMessage);
  };

  const sendRefreshMessage = (websocket: WebSocket) => {
    const refreshMessage = JSON.stringify({
      type: "refresh",
      workoutId,
      locationId,
      timerState,
    });
    websocket.send(refreshMessage);
  };

  useEffect(() => {
    const fetchTimerState = async () => {
      try {
        const locationTimerResponse = await api.get(`/locations/${locationId}/timer-state`);
        const locationTimer = locationTimerResponse.data;

        if (locationTimer) {
          if (locationTimer.workoutId === workoutId) {
            setModalMessage("This workouts is already running, would you like to restart it?");
            setModalAText("Restart");
            setModalBText("Continue");
            setOnModalResponse(() => (userChoice: boolean) => {
              if (userChoice) {
                fetchAndSetTimer();
              } else {
                setTimerData(locationTimer.timerData)
                setTimerState(locationTimer.timerState)
                setShouldSendResetMessage(true);
              }
              setIsModalOpen(false);
            });
            setIsModalOpen(true);
          } else {
            setModalMessage("There is another workout running at this location, open it?");
            setModalAText("Open");
            setModalBText("Load new workout instead");
            setOnModalResponse(() => (userChoice: boolean) => {
              if (userChoice) {
                navigate(`/location/${locationTimer.locationId}/workout/${locationTimer.workoutId}/coach`);
              } else {
                fetchAndSetTimer();
              }
              setIsModalOpen(false);
            });
            setIsModalOpen(true);
          }
        } else {
          await fetchAndSetTimer();
        }
      } catch (error) {
        console.error("Error fetching timer state:", error);
        await fetchAndSetTimer();
      }
    };

    fetchTimerState();
  }, [workoutId, locationId]);

  const toggleDetailView = (booking: Booking | null) => {
    setSelectedBooking(booking);
    setIsDetailView(!isDetailView);
  };

  const handleCheckIn = async () => {
    if (selectedBooking) {
      try {
        const updatedStatus = !selectedBooking.has_attended;
        await api.put(`/bookings/${selectedBooking.id}/attendance`, {
          attendance: updatedStatus,
        });
        setSelectedBooking({ ...selectedBooking, has_attended: updatedStatus });
      } catch (error) {
        console.error("Error updating booking attendance:", error);
      }
    }
  };

  const handleRefresh = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      sendRefreshMessage(ws);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null; // Initialize with null
    if (timerState.isRunning) {
      intervalId = setInterval(() => {
        setTimerState((prevState) => {
          if (prevState.timeLeft > 1) {
            return { ...prevState, timeLeft: prevState.timeLeft - 1 };
          } else {
            if (prevState.intervalIndex < (timerData?.intervals.length || 0) - 1) {
              const newIntervalIndex = prevState.intervalIndex + 1;
              const newTimeLeft =
                timerData?.intervals[newIntervalIndex].duration || 0;
              return {
                ...prevState,
                intervalIndex: newIntervalIndex,
                timeLeft: newTimeLeft,
              };
            } else {
              clearInterval(intervalId!); // Use non-null assertion
              return { ...prevState, isRunning: false };
            }
          }
        });
      }, 1000);
    } else if (!timerState.isRunning && intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timerState.isRunning, timerData]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const handleClose = () => {
    if (workoutData) {
      navigate(`/location/${workoutData.location_id}/workouts`);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setFilteredBookings(
      bookings.filter((booking) =>
        `${booking.customer_first_name} ${booking.customer_last_name}`
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  const currentInterval = timerData?.intervals?.[timerState.intervalIndex];
  const intervalType = currentInterval?.intervalType
    ? currentInterval.intervalType === "Pause"
      ? "Break"
      : currentInterval.intervalType
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";

  useEffect(() => {
    const checkValidWOD = async () => {
      try {
        await api.get("/wods", {
          params: { date: new Date().toISOString().split("T")[0], is_valid: true },
        });
      } catch (error) {
        if (
          error instanceof AxiosError && // Type guard for AxiosError
          (error.response &&
            (error.response.status === 400 || error.response.status === 404))
        ) {
          toast.warn("Workout of the day missing for today", {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            style: {
              lineHeight: "3rem",
              width: "600px",
            },
          });
        } else {
          console.warn("Error checking valid WOD:", error);
        }
      }
    };

    checkValidWOD();
  }, []);

  const handleStart = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "startTimer", locationId, timerState }));
    }
  };

  const handlePause = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ type: "pauseTimer", locationId, timerState }));
    }
  };

  const handleSkip = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const newIntervalIndex = timerState.intervalIndex + 1;
      const newTimeLeft = timerData?.intervals[newIntervalIndex].duration || 0;
      const newTimerState = {
        ...timerState,
        intervalIndex: newIntervalIndex,
        timeLeft: newTimeLeft,
      };
      ws.send(JSON.stringify({ type: "skipInterval", locationId, timerState: newTimerState }));
    }
  };

  const adjustWeight = async (exerciseId: string, adjustment: number) => {
    if (!selectedBooking) return;

    const customerWeights = allCustomerWeights[selectedBooking.id];
    const exercise = customerWeights.find(ex => ex.exerciseId === exerciseId);

    if (!exercise) {
      console.error("Exercise not found");
      return;
    }

    const newWeight = getNextWeight(exercise.equipment, exercise.weight, adjustment);

    // Determine which RM field to update based on reps
    const rmField = `weight_${exercise.reps}rm`;

    try {
      await api.post(`/customer_strength_profiles`, {
        customer_id: selectedBooking.customer_id,
        exercise_id: exerciseId,
        [rmField]: newWeight, // Use computed property name
      });

      setAllCustomerWeights((prevWeights) => ({
        ...prevWeights,
        [selectedBooking.id]: prevWeights[selectedBooking.id].map(ex =>
          ex.exerciseId === exerciseId ? { ...ex, weight: newWeight } : ex
        ),
      }));

      // Send booking update notification over WebSocket
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({
          type: "bookingUpdate",
          workoutId,
        }));
      }
    } catch (error) {
      console.error("Error updating strength profile:", error);
    }
  };

  const handleSpotButtonClick = () => {
    setIsSpotSelectionVisible(true);
  };

  const handleSpotSelectionClose = () => {
    setIsSpotSelectionVisible(false);
  };

  const handleSpotSave = async (selectedSpot: string) => {
    if (selectedBooking) {
      try {
        await api.put(`/bookings/${selectedBooking.id}/assign_spot`, { spot: selectedSpot });

        // Update the booking with the new spot immediately
        setSelectedBooking(prev => prev ? { ...prev, spot: selectedSpot } : null);

        // Update the bookings list with the new spot
        setBookings(prevBookings =>
          prevBookings.map(booking =>
            booking.id === selectedBooking.id ? { ...booking, spot: selectedSpot } : booking
          )
        );
      } catch (error) {
        console.error('Error assigning spot:', error);
      }
    }
    setIsSpotSelectionVisible(false);
  };

  const bookedSpots = bookings.map(booking => booking.spot).filter(spot => spot !== null) as string[];

  return (
    <div className="location-tablet">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {isSpotSelectionVisible && selectedBooking ? (
            <LocationSpotSelection
              floorplanUrl={floorplanUrl || ''}
              maxSpots={maxSpots}
              bookedSpots={bookedSpots}
              onClose={handleSpotSelectionClose}
              onSave={handleSpotSave}
              greeting="Hey coach,"
            />
          ) :
            isDetailView && selectedBooking ? (
              <LocationWeightAdjustment
                selectedBooking={selectedBooking}
                allCustomerWeights={allCustomerWeights}
                toggleDetailView={toggleDetailView}
                handleCheckIn={handleCheckIn}
                adjustWeight={adjustWeight}
                showButtonRow={true}
                onSpotButtonClick={handleSpotButtonClick}
              />
            ) : (
              <>

                <div className="flex-vertical gap-xs">
                  <div className="top-info-buttons">
                    <button className="button-s secondary" onClick={handleRefresh}>
                      <RefreshIcon className="icon-l" />
                      Refresh screens
                    </button>
                    <button className="button-s secondary square" onClick={handleClose}>
                      <CloseIcon className="icon-l"
                      />
                    </button>
                  </div>
                  <div className="flex-vertical">
                    <h2>Coach {workoutData?.coach_first_name}</h2>
                    <h1>{timerData?.name}</h1>
                    <h3>
                      at{" "}
                      {workoutData?.start_datetime &&
                        new Date(workoutData.start_datetime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                    </h3>
                  </div>
                </div>
                <div className="flex-horizontal space-between">
                  <button className="button-l secondary square" onClick={handleSkip}>
                    Skip
                  </button>
                  <div className="flex-vertical centered">
                    <div className="flex-vertical">
                      {currentInterval?.exercise_number && (
                        <p>
                          Exercise {currentInterval.exercise_number}
                        </p>
                      )}
                      <p style={{ fontWeight: 'bold' }}>{intervalType}</p>
                    </div>
                    <div className="coach-timer-circle">
                      <svg width="360" height="360" viewBox="0 0 360 360" className="desktop-timer-circle">
                        <circle
                          cx="180"
                          cy="180"
                          r="162"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="8"
                        />
                        <circle
                          cx="180"
                          cy="180"
                          r="117"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="8"
                        />
                        <circle
                          className="coach-timer-circle-progress"
                          cx="180"
                          cy="180"
                          r="140"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="40"
                          strokeLinecap="square"
                          style={{
                            strokeDasharray: strokeDashArray,
                            transform: "rotate(-90deg)",
                            transformOrigin: "center",
                          }}
                        />
                      </svg>
                      <svg width="164" height="164" viewBox="0 0 164 164" className="mobile-timer-circle">
                        <circle
                          cx="82"
                          cy="82"
                          r="72"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="5"
                        />
                        <circle
                          cx="82"
                          cy="82"
                          r="50"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="5"
                        />
                        <circle
                          className="coach-timer-circle-progress"
                          cx="82"
                          cy="82"
                          r="62"
                          fill="none"
                          stroke="var(--color-dark-blue)"
                          strokeWidth="20"
                          strokeLinecap="square"
                          style={{
                            strokeDasharray: strokeDashArray,
                            transform: "rotate(-90deg)",
                            transformOrigin: "center",
                          }}
                        />
                      </svg>
                      <div className="coach-timer-text">
                        <span>{formatTime(timerState.timeLeft)}</span>
                      </div>
                    </div>
                  </div>
                  {timerState.isRunning ? (
                    <button className="button-l square" onClick={handlePause}>
                      <PauseIcon className="icon-xl" />
                      <span>Pause</span>
                    </button>
                  ) : (
                    <button className="button-l square" onClick={handleStart}>
                      <PlayArrowIcon className="icon-xl" />
                      <span>Start</span>
                    </button>
                  )}
                </div>
                <div className="flex-horizontal full-width">
                  <SearchIcon className="icon-xl"
                  />
                  <input
                    type="text"
                    className="input-field"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                <div className="card-list">

                  {filteredBookings.map((booking, index) => (
                    <div
                      key={index}
                      className={`card-l ${booking.has_attended ? "active" : ""}`}
                      onClick={() => toggleDetailView(booking)}
                    >

                      {booking.customer_profile_image_url ? (
                        <div className="profile-avatar-l">
                          <img
                            src={booking.customer_profile_image_url}
                            alt="Profile"
                            className="profile-avatar-l"
                          />
                          {booking.workout_number === 1 || booking.workout_number === 5 || booking.workout_number === 10 || booking.workout_number === 25 || booking.workout_number % 50 === 0 ? (
                        <div className="workout-number-circle">
                          {booking.workout_number}
                        </div>
                      ) : null}
                        </div>
                      ) : (
                        <div className="profile-placeholder-l">
                          {booking.customer_first_name.charAt(0)}
                          {booking.customer_last_name.charAt(0)}
                          {booking.workout_number === 1 || booking.workout_number === 5 || booking.workout_number === 10 || booking.workout_number === 25 || booking.workout_number % 50 === 0 ? (
                        <div className="workout-number-circle">
                          {booking.workout_number}
                        </div>
                      ) : null}
                        </div>
                      )}
                      
                      <span>
                        {`${booking.customer_first_name} ${booking.customer_last_name.slice(0, 2)}.`}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
        </>
      )}
      <TwoChoiceModal
        isOpen={isModalOpen}
        onResponse={onModalResponse}
        message={modalMessage}
        aText={modalAText}
        bText={modalBText}
      />
      <ToastContainer />
    </div>
  );
};

export default LocationCoachView;