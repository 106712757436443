export const weightIncrements = {
  "Leg Press": [4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192, 200],
  "Cable": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 95, 100, 105, 110, 115],
  "Dumbbell": [2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25, 27.5, 30],
  "Barbell": [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
  "Smith Machine": [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 95, 100, 105]
};

type EquipmentType = keyof typeof weightIncrements;

export const getNextWeight = (equipment: EquipmentType, currentWeight: number, change: number): number => {
  const increments = weightIncrements[equipment];
  if (!increments) return currentWeight;

  const currentIndex = increments.indexOf(currentWeight);
  if (currentIndex === -1) return currentWeight;

  const newIndex = currentIndex + change;
  const clampedIndex = Math.max(0, Math.min(newIndex, increments.length - 1));
  return increments[clampedIndex];
};

export const calculateWeightDifference = (equipment: EquipmentType, currentWeight: number, change: number): number | null => {
  const increments = weightIncrements[equipment];
  if (!increments) return null;

  const currentIndex = increments.indexOf(currentWeight);
  if (currentIndex === -1) return null;

  const newIndex = currentIndex + change;
  if (newIndex < 0 || newIndex >= increments.length) return null;

  return increments[newIndex] - currentWeight;
};

export const getPlateConfiguration = (targetWeight: number): string => {
    // If weight is 10kg (just the bar), return "Bar only"
    if (targetWeight === 10) {
        return "Bar only";
    }

    // Calculate weight needed on each side (subtract bar weight and divide by 2)
    const weightPerSide = (targetWeight - 10) / 2;

    // Available plate weights
    const plates = [20, 10, 5, 2.5];
    let remainingWeight = weightPerSide;
    const usedPlates: number[] = [];

    // Greedy algorithm to select plates
    for (const plate of plates) {
        while (remainingWeight >= plate && 
              // Check plate count limits
              ((plate === 20 && usedPlates.filter(p => p === 20).length < 4) ||
               (plate === 10 && usedPlates.filter(p => p === 10).length < 4) ||
               (plate === 5 && usedPlates.filter(p => p === 5).length < 2) ||
               (plate === 2.5 && usedPlates.filter(p => p === 2.5).length < 2))) {
            usedPlates.push(plate);
            remainingWeight -= plate;
        }
    }

    // If we couldn't match the weight exactly, return null
    if (remainingWeight > 0) {
        return "Invalid weight";
    }

    // Format the plate configuration
    if (usedPlates.length === 0) {
        return "Bar only";
    }

    return usedPlates
        .sort((a, b) => b - a) // Sort descending
        .join(" + ");
}; 