import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig';
import "./WorkoutWeightDisplay.css";
import "./WorkoutPlayer.css";
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { getPlateConfiguration } from './utils/weightUtils';

interface Exercise {
    name: string;
    short_name: string;
    reps: number;
    video_url: string;
    exercise_number: number;
    set_type: string;
    exercise_id: string;
    workout_exercise_id: string;
    equipment: string;
    per_side: boolean;
}

interface SpotGroup {
    spot: string;
    customer: {
        customerId: string;
        firstName: string;
        lastName: string;
    } | null;
    exercises: {
        exerciseId: string;
        weight: number;
    }[];
}

interface WeightDisplaySequence {
    intervalType: string;
    duration: number;
    displayState: string;
    displayGroup: number;
    setNumber: number;
    nextSetNumber: number;
}

// Define an interface for exerciseLogData
interface ExerciseLogData {
    customer_id: string;
    workout_id: string;
    exercise_id: string;
    equipment: string;
    set_number: number;
    set_type: string;
    reps: number;
    suggested_weight: number;
    exercise_number: number;
    actual_weight: number;
    timestamp: string;
}

function WorkoutWeightDisplay() {
    const { locationId, displayNumber } = useParams<{ locationId: string, displayNumber: string }>();
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [spotGroups, setSpotGroups] = useState<Record<string, SpotGroup>>({});
    const [error, setError] = useState<string | null>(null);
    const [weightDisplaySequence, setWeightDisplaySequence] = useState<WeightDisplaySequence[]>([]);
    const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const workoutIdRef = useRef<string | null>(null);
    const [loading, setLoading] = useState(true);

    // WebSocket Connection
    const [ws, setWs] = useState<WebSocket | null>(null);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [submittedLogs, setSubmittedLogs] = useState<Set<string>>(new Set());

    const dataFetchedRef = useRef(false);

    // Define fetchData function
    const fetchData = async (id: string) => {
        console.log("Entering fetch data with workoutId:", id);
        try {
            console.log("Getting data");
            const response = await api.get(`/workouts/${id}/weights/${displayNumber}`);
            setExercises(response.data.exercisesToDisplay);
            setSpotGroups(response.data.spotGroups);
            setWeightDisplaySequence(response.data.weightDisplaySequence);
            setLoading(false);
            dataFetchedRef.current = true; // Mark data as fetched
        } catch (error) {
            const err = error as { response?: { data?: { error: string } } };
            setError(err.response?.data?.error || "An error occurred");
            setLoading(false);
        }
    };

    useEffect(() => {
        const connectWebSocket = () => {
            const wsUrl = process.env.NODE_ENV === 'production'
                ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
                : "ws://localhost:5000";

            const newWs = new WebSocket(wsUrl);

            newWs.onopen = () => {
                console.log("WorkoutWeightDisplay connected to WebSocket server");
                newWs.send(JSON.stringify({ type: "join", locationId }));
            };

            newWs.onmessage = (message) => {
                console.log("WebSocket message received:", message.data);
                const data = JSON.parse(message.data);
                if ((data.type === 'refreshTimer' || data.type === 'resetTimer') &&
                    data.locationId === locationId) {
                    workoutIdRef.current = data.workoutId;
                    setCurrentIntervalIndex(data.timerState.intervalIndex);
                    setTimeLeft(data.timerState.timeLeft);
                    setIsRunning(data.timerState.isRunning);
                    fetchData(data.workoutId)
                } else if (data.type === "timerUpdate" &&
                    data.locationId === locationId) {
                    workoutIdRef.current = data.workoutId;
                    setCurrentIntervalIndex(data.timerState.intervalIndex);
                    setTimeLeft(data.timerState.timeLeft);
                    setIsRunning(data.timerState.isRunning);
                    if (!dataFetchedRef.current) { // Check if data has not been fetched
                        fetchData(data.workoutId);
                    }
                }
                console.log("Current workoutId state: ", workoutIdRef.current)
                if (data.type === "bookingUpdate" && workoutIdRef.current === data.workoutId) {
                    console.log("Booking update received with matching workoutId:", workoutIdRef.current);
                    fetchData(data.workoutId);
                }
            };

            newWs.onclose = () => {
                console.log("WebSocket connection closed. Attempting to reconnect...");
                reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
            };

            newWs.onerror = (error) => {
                console.error("WebSocket error:", error);
                newWs.close();
            };

            setWs(newWs);
        };

        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [locationId]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (isRunning && weightDisplaySequence.length > 0) {
            intervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft > 1) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(intervalId);
                        if (currentIntervalIndex < weightDisplaySequence.length - 1) {
                            const newIntervalIndex = currentIntervalIndex + 1;
                            const newTimeLeft = weightDisplaySequence[newIntervalIndex].duration;

                            setCurrentIntervalIndex(newIntervalIndex);
                            setTimeLeft(newTimeLeft);
                        } else {
                            setIsRunning(false);
                        }
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isRunning, currentIntervalIndex, weightDisplaySequence]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    };

    const currentInterval = weightDisplaySequence[currentIntervalIndex];
    const currentIntervalType = currentInterval?.intervalType
        ?.replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    const exercisesToDisplay = exercises.filter(exercise => exercise.set_type === currentIntervalType);
    const displayText = currentIntervalType?.includes("Main")
        ? "Exercise"
        : currentIntervalType?.includes("Change")
            ? "Long Break"
            : currentIntervalType?.includes("Pause")
                ? "Break"
                : currentIntervalType;

    // Sort exercises by exercise number
    exercisesToDisplay.sort((a, b) => a.exercise_number - b.exercise_number);

    // Function to get stroke color based on interval type
    const getStrokeColorForInterval = (currentIntervalType: string) => {
        if (currentIntervalType?.includes("Main") || currentIntervalType?.includes("Challenge")) {
            return "var(--color-neon-blue)";
        } else if (
            currentIntervalType?.includes("Break") ||
            currentIntervalType?.includes("Switch") ||
            currentIntervalType?.includes("Stretching") ||
            currentIntervalType?.includes("Breathing") ||
            currentIntervalType?.includes("Station")
        ) {
            return "var(--color-grey)";
        } else if (currentIntervalType?.includes("Warmup")) {
            return "var(--color-yellow)";
        } else {
            return "var(--color-cream)";
        }
    };

    const strokeColor = getStrokeColorForInterval(currentIntervalType);

    const createExerciseLog = async (exerciseLogData: ExerciseLogData) => {
        const logKey = `${exerciseLogData.customer_id}-${exerciseLogData.exercise_id}-${exerciseLogData.set_number}-${exerciseLogData.actual_weight}`;

        if (!submittedLogs.has(logKey)) {
            try {
                await api.post('/exercise_logs', exerciseLogData);
                setSubmittedLogs(prev => new Set(prev).add(logKey));
            } catch (error) {
                console.error("Error creating exercise log:", error);
            }
        }
    };

    const handleIntervalEnd = () => {
        const currentInterval = weightDisplaySequence[currentIntervalIndex];
        if (!currentInterval || !currentInterval.setNumber) return;

        Object.values(spotGroups).forEach(spotGroup => {
            // Check if spot number matches current display group
            const spotNumber = parseInt(spotGroup.spot[0]);
            if (spotGroup.customer && spotNumber === currentInterval.displayGroup) {
                exercises.forEach(exercise => {
                    if (workoutIdRef.current && spotGroup.customer) {
                        const exerciseLogData = {
                            customer_id: spotGroup.customer.customerId,
                            workout_id: workoutIdRef.current,
                            exercise_id: exercise.exercise_id,
                            equipment: exercise.equipment,
                            set_number: currentInterval.setNumber,
                            set_type: exercise.set_type,
                            reps: exercise.reps,
                            suggested_weight: spotGroup.exercises.find(e => e.exerciseId === exercise.exercise_id)?.weight || 0,
                            exercise_number: exercise.exercise_number,
                            actual_weight: spotGroup.exercises.find(e => e.exerciseId === exercise.exercise_id)?.weight || 0,
                            timestamp: new Date().toISOString()
                        };
                        createExerciseLog(exerciseLogData);
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (timeLeft === 1 && isRunning && currentInterval?.setNumber) {
            handleIntervalEnd();
        }
    }, [timeLeft, isRunning, currentInterval?.setNumber]);

    // Calculate progress percentage for current interval
    const progressPercentage = currentInterval
        ? ((currentInterval.duration - timeLeft) / currentInterval.duration) * 100
        : 0;

    const renderSetProgress = () => {
        if (!weightDisplaySequence.length) return null;

        // Find max set number from weightDisplaySequence
        const maxSetNumber = Math.max(...weightDisplaySequence
            .filter(interval => interval.setNumber)
            .map(interval => interval.setNumber || 0)
        );

        return (
            <div className="weight-set-progress-container">
                <div className="weight-set-progress-header">
                    {[...Array(maxSetNumber)].map((_, index) => (
                        <div key={index} className="weight-set-element">Set {index + 1}</div>
                    ))}
                </div>
                <div className="weight-set-progress-row">
                    {[...Array(maxSetNumber)].map((_, setNumber) => {
                        const isBreakInterval = ["break", "pause", "change_station", "setup"].includes(
                            weightDisplaySequence[currentIntervalIndex]?.intervalType.toLowerCase()
                        );

                        return (
                            <div key={setNumber} className="weight-set-element">
                                {weightDisplaySequence.some((interval, index) =>
                                    (interval.setNumber >= setNumber + 1 || interval.nextSetNumber > setNumber + 1) &&
                                    currentIntervalIndex === index
                                ) ? <FaCheckCircle /> : ""}
                                {isBreakInterval && weightDisplaySequence.some((interval, index) =>
                                    interval.nextSetNumber === setNumber + 1 &&
                                    currentIntervalIndex === index
                                ) ? <FaCircle className="blinking" /> : ""}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={`workout-weight-display ${currentInterval?.displayState === 'blank' ? 'blank' : ''}`}>
            {loading ? (
                <>
                    <svg
                        className={`lifted-logo ${currentInterval?.displayState === 'blank' && currentInterval?.displayState ? 'inverted' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 249 44"
                        fill="currentColor" // This will inherit the color from CSS
                    >
                        <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
                        <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
                        <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
                        <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
                        <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
                        <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
                    </svg>
                    <div className="loading-message">Loading workout...</div>
                </>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : currentInterval?.displayState === 'blank' && currentInterval?.displayState ? (
                <svg
                    className={`lifted-logo ${currentInterval?.displayState === 'blank' && currentInterval?.displayState ? 'inverted' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 249 44"
                    fill="currentColor" // This will inherit the color from CSS
                >
                    <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
                    <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
                    <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
                    <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
                    <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
                    <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
                </svg>
            ) : (
                <>
                    <div className="station-title">
                        <div>{`Station ${displayNumber}: ${exercises[0] ? exercises[0].short_name || exercises[0].name : ""}`}</div>
                        {exercises[0] && <div>{`- ${exercises[0].reps} Reps ${exercises[0].per_side ? "/ side" : ""} -`}</div>}
                    </div>
                    <div className="exercise-customer-container">
                        <div className="exercise-section">
                            {exercises[0] && (
                                <div className="exercise-info">
                                    <div className="exercise-video-box">
                                        <div className="exercise-video">
                                            <video 
                                                src={exercises[0].video_url.replace('/upload/', '/upload/q_auto,w_640/')} 
                                                autoPlay 
                                                loop 
                                                muted 
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="weight-timer-container">
                                <div className="weight-timer-content">
                                    <div className="weight-description-text">{displayText}</div>
                                    <div className="weight-time-left">{formatTime(timeLeft)}</div>
                                </div>
                                <div className="weight-progress-bar-container">
                                    <div className="weight-progress-bar">
                                        <div
                                            className="weight-progress-bar-fill"
                                            style={{ width: `${progressPercentage}%` }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {renderSetProgress()}
                        </div>
                        <div className="customer-section">
                            {Object.values(spotGroups)
                                .filter(spotGroup => {
                                    const spotNumber = parseInt(spotGroup.spot[0]);
                                    return spotNumber === currentInterval?.displayGroup &&
                                        (currentInterval?.displayState === 'spots' || spotGroup.customer);
                                })
                                .sort((a, b) => a.spot[1].localeCompare(b.spot[1]))
                                .map((spotGroup, index) => (
                                    <div key={index} className="customer-box">
                                        <div className="spot-label">
                                            {`${displayNumber}${spotGroup.spot.slice(1)}`}
                                        </div>
                                        <div className="customer-name">
                                            {spotGroup.customer ?
                                                `${spotGroup.customer.firstName} ${spotGroup.customer.lastName.slice(0, 2)}.` :
                                                ''}
                                        </div>
                                        <div className="customer-weight">
                                            {spotGroup.exercises[0]?.weight ? (
                                                <>
                                                    {`${Number(spotGroup.exercises[0].weight) % 1 === 0 ? 
                                                        Math.floor(spotGroup.exercises[0].weight) : 
                                                        spotGroup.exercises[0].weight} kg`}
                                                    {exercises[0]?.equipment === "Barbell" && (
                                                        <div className="plate-suggestion">
                                                            {getPlateConfiguration(spotGroup.exercises[0].weight)}
                                                        </div>
                                                    )}
                                                </>
                                            ) : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </>

            )}
        </div>
    );
}

export default WorkoutWeightDisplay;
