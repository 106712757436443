import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import api from './axiosConfig';
import { useNavigate, useParams } from "react-router-dom";
import "./AdminForm.css";
import Select, { MultiValue, StylesConfig } from "react-select";
import Layout from "./Layout";

interface Customer {
  first_name: string;
  last_name: string;
  sex: string;
  birthdate: string;
  weight: number;
  height: number;
  email: string;
  user_id: string;
  id?: string; // Assuming id is optional
}

interface Role {
  id: string; // or number, depending on your data
  name: string;
}

interface RoleOption {
  value: string;
  label: string;
}

function CustomerEdit() {
  const { customerId } = useParams<{ customerId: string }>();
  const navigate = useNavigate();
  const [originalCustomer, setOriginalCustomer] = useState<Customer | null>(null);
  const [customer, setCustomer] = useState<Customer>({
    first_name: "",
    last_name: "",
    sex: "",
    birthdate: "",
    weight: 0,
    height: 0,
    email: "",
    user_id: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const sexes = ["male", "female", "other"];
  const [availableRoles, setAvailableRoles] = useState<RoleOption[]>([]);
  const [originalRoles, setOriginalRoles] = useState<RoleOption[]>([]); // Change to RoleOption[]
  const [selectedRoles, setSelectedRoles] = useState<MultiValue<RoleOption>>([]); // Change to RoleOption[]

  useEffect(() => {
    const fetchCustomerData = async () => {
      setIsLoading(true);

      try {
        const customerResponse = await api.get<Customer>(
          `/customers/${customerId || ""}`
        );
        const customerData = customerResponse.data;
        setCustomer(customerData);
        setOriginalCustomer(customerData);

        const [rolesResponse, userRolesResponse] = await Promise.all([
          api.get<Role[]>("/roles"),
          api.get<string[]>(`/users/${customerData.user_id}/roles`),
        ]);

        const fetchedRoles = rolesResponse.data.map((role: Role) => ({
          value: role.id,
          label: role.name,
        }));
        setAvailableRoles(fetchedRoles as RoleOption[]);

        const userRoleIds = userRolesResponse.data;
        const userRoles = fetchedRoles.filter((role) => userRoleIds.includes(role.label));
        setOriginalRoles(userRoles); // Set filtered roles as RoleOption[]
        setSelectedRoles(userRoles); // Set filtered roles as RoleOption[]
        
      } catch (err) {
        console.error("Error fetching customer:", err);
        setError("Failed to fetch customer");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]:
        name === "weight" || name === "height" ? parseInt(value, 10) : value,
    }));
  };

  const handleRoleChange = (selectedOptions: MultiValue<RoleOption>) => {
    setSelectedRoles(selectedOptions);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (JSON.stringify(customer) !== JSON.stringify(originalCustomer)) {
        await api.put(`/customers/${customerId}`, customer);
      }
      const newRoleIds = selectedRoles.map(role => role.value);
      const oldRoleIds = originalRoles.map(role => role.value);
      const rolesToAdd = newRoleIds.filter(id => !oldRoleIds.includes(id));
      const rolesToRemove = oldRoleIds.filter(id => !newRoleIds.includes(id));

      for (const roleId of rolesToAdd) {
        await api.post(`/users/${customer.user_id}/roles/${roleId}`);
      }

      for (const roleId of rolesToRemove) {
        await api.delete(`/users/${customer.user_id}/roles/${roleId}`);
      }

      navigate(`/customer/${customer.id}`);
    } catch (err) {
      console.error("Error saving/updating customer:", err);
      setError("Failed to save customer");
    } finally {
      setIsLoading(false);
    }
  };

  const customStyles: StylesConfig<RoleOption, true> = { // Change Role to RoleOption
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="admin-form-container">
        <h2>Edit Customer</h2>
        <form onSubmit={handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="first_name">First Name:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={customer.first_name}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="last_name">Last Name:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={customer.last_name}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="email">Email:</label>
                </td>
                <td>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={customer.email}
                    onChange={handleChange}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="sex">Sex:</label>
                </td>
                <td>
                  <select
                    id="sex"
                    name="sex"
                    value={customer.sex}
                    onChange={handleChange}
                  >
                    <option value="">Select a Sex</option>
                    {sexes.map((sex) => (
                      <option key={sex} value={sex}>
                        {sex}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="birthdate">Birthdate:</label>
                </td>
                <td>
                  <input
                    type="date"
                    id="birthdate"
                    name="birthdate"
                    value={customer.birthdate}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="weight">Weight (kg):</label>
                </td>
                <td>
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    value={customer.weight}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="height">Height (cm):</label>
                </td>
                <td>
                  <input
                    type="number"
                    id="height"
                    name="height"
                    value={customer.height}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <h3>Roles</h3>
            <Select
              isMulti
              options={availableRoles}
              value={selectedRoles}
              onChange={handleRoleChange}
              styles={customStyles}
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={isLoading}>
            Update Customer
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default CustomerEdit;