import React from "react";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div className="admin-form-container">
      <h1>You do not have permissions to view this page.</h1>
      <p>Please contact an administrator or login with a different account.</p>
      <div>
      <button onClick={handleBackToHome}>
        Back to Home
      </button>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
