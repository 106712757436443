import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import { Link } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import "./AdminList.css"; // Import the new CSS file
import Layout from "./Layout";

interface Booking {
  id: string;
  customer_id: string;
  workout_id: string;
  spot: string;
  created_at: string;
  customer_first_name: string;
  customer_last_name: string;
  customer_profile_image_url: string;
  workout_start_datetime: string;
  location_name: string;
  bsport_id: string; // Add bsport_id to the Booking interface
}

function BookingPage() {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const fetchBookings = async () => {
    setIsLoading(true);
    try {
      const response = await api.get<Booking[]>("/bookings");
      const bookingsData = response.data;

      setBookings(bookingsData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch bookings and related data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  const handleCopy = (bookingId: string) => {
    navigator.clipboard.writeText(bookingId)
      .then(() => {
        toast.success(`Booking ID: ${bookingId} copied!`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy booking ID. Please try again.", {
          position: "top-center",
        });
      });
  };

  const filteredBookings = bookings.filter((booking) => {
    const terms = searchQuery.toLowerCase().split(" ");
    return terms.every(term =>
      booking.customer_first_name.toLowerCase().includes(term) ||
      booking.customer_last_name.toLowerCase().includes(term) ||
      booking.location_name.toLowerCase().includes(term) ||
      new Date(booking.workout_start_datetime).toLocaleString().toLowerCase().includes(term)
    );
  });

  if (isLoading) {
    return <p>Loading bookings...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Bookings</h2>
        <input
          type="text"
          placeholder="Search by date, location, or customer name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
        <table className="admin-list-table">
          <thead>
            <tr>
              <th>Customer</th>
              <th>Workout</th>
              <th>Spot Number</th>
              <th>Created At</th>
              <th>Bsport ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings.map((booking) => (
              <tr key={booking.id}>
                <td>
                  <Link to={`/customer/${booking.customer_id}`}>
                    {booking.customer_first_name} {booking.customer_last_name}
                  </Link>
                </td>
                <td>
                  <Link to={`/workout/${booking.workout_id}`}>
                    {new Date(booking.workout_start_datetime).toLocaleString([], { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit' })} at {booking.location_name}
                  </Link>
                </td>
                <td>{booking.spot}</td>
                <td>{new Date(booking.created_at).toLocaleString([], { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit' })}</td>
                <td>{booking.bsport_id}</td>
                <td>
                  <div className="actions">
                    <button onClick={() => handleCopy(booking.id)}>
                      <FaCopy title="Copy ID" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </Layout>
  );
}

export default BookingPage;
