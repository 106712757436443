import React, { useState, useEffect } from "react";
import api from './axiosConfig';
import "./AdminList.css";
import { useNavigate } from "react-router-dom";
import {
  FaEye,
  FaPencilAlt,
  FaCopy,
  FaCheck,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import moment from "moment-timezone";
import { Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Wod {
  id: string;
  date: string;
  timer_id: string;
  timer_name?: string;
  exercise_count: number;
  is_valid: boolean;
}

interface Timer {
  id: string;
  name: string;
  exercise_count: number;
}

function WodPage() {
  const [wods, setWods] = useState<Wod[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const [timers, setTimers] = useState<Timer[]>([]);
  const [editingWodId, setEditingWodId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const [tempFilterDate, setTempFilterDate] = useState<Date>(new Date());
  const limit = 30;
  const navigate = useNavigate();

  const fetchWods = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/wods", {
        params: {
          page: currentPage,
          limit,
          date_after: filterDate.toISOString().split('T')[0],
        },
      });
      
      const totalCount = response.headers['x-total-count'];
      setTotalPages(Math.ceil(totalCount / limit));
      
      const adjustedWods = response.data.map((wod: Wod) => ({
        ...wod,
        date: moment(wod.date).tz('Europe/Berlin').format('YYYY-MM-DD')
      }));

      adjustedWods.sort((a: Wod, b: Wod) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setWods(adjustedWods);
    } catch (error) {
      console.error("Error fetching WODs:", error);
      setError("Failed to fetch WODs");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTimers = async () => {
    try {
      const response = await api.get("/timers");
      setTimers(response.data);
    } catch (error) {
      console.error("Error fetching timers:", error);
    }
  };

  useEffect(() => {
    fetchWods();
    fetchTimers();
  }, [currentPage, filterDate]);

  const handleWodInputChange = (event: React.SyntheticEvent<HTMLElement>, field: string, value: any, wodId: string) => {
    const newValue = value !== undefined ? value : (event.target as HTMLInputElement).value;
    setWods(prevWods => {
      const updatedWods = prevWods.map(wod => 
        wod.id === wodId ? { ...wod, [field]: newValue } : wod
      );
      updatedWods.sort((a: Wod, b: Wod) => new Date(a.date).getTime() - new Date(b.date).getTime());
      return updatedWods;
    });
  };

  const handleSaveWod = async (id: string) => {
    try {
      const wodToUpdate = wods.find(wod => wod.id === id);
      if (wodToUpdate) {
        await api.put(`/wods/${id}`, wodToUpdate);
        setEditingWodId(null);
        fetchWods();
      }
    } catch (error) {
      console.error("Error updating WOD:", error);
      setError("Failed to update WOD");
    }
  };

  const handleCancelEdit = () => {
    setEditingWodId(null);
  };

  const handleCopy = (wodId: string) => {
    navigator.clipboard.writeText(wodId.toString())
      .then(() => {
        toast.success(`WOD ID: ${wodId} copied!`, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy WOD ID. Please try again.", {
          position: "top-center",
        });
      });
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const formatDate = (dateString: string) => {
    return moment(dateString).format("DD.MM.YYYY");
  };

  const getWeekday = (dateString: string) => {
    return moment(dateString).format("dddd");
  };

  const timerOptions = timers.map(timer => ({
    key: timer.id,
    text: timer.name,
    value: timer.id,
  }));

  const pagesArray = Array.from({ length: totalPages }, (_, index) => index + 1);

  if (isLoading) {
    return <p>Loading WODs...</p>;
  }

  if (error) {
    return <p className="error-message">Error: {error}</p>;
  }

  return (
    <Layout>
      <div className="admin-list-container">
        <h2>Workouts of the Day</h2>
        <div className ="filter-row">
          <label>Only show after date:</label>
          <DatePicker
            selected={tempFilterDate}
            onChange={(date: Date | null) => date && setTempFilterDate(date)}
            dateFormat="yyyy-MM-dd"
          />
          <button onClick={() => setFilterDate(tempFilterDate)}>Apply</button>
        </div>
        <div className="scrollable-table-container">
          <table className="admin-list-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Weekday</th>
                <th>Timer Name</th>
                <th>Exercise Count</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {wods.map((wod) => {
                const isCountNullOrZero = wod.exercise_count === null || wod.exercise_count === 0;

                return (
                  <tr key={wod.id}>
                    <td>{formatDate(wod.date)}</td>
                    <td>{getWeekday(wod.date)}</td>
                    <td>
                      {editingWodId === wod.id ? (
                        <Dropdown
                          placeholder="Select Timer"
                          fluid
                          selection
                          options={timerOptions}
                          value={wod.timer_id}
                          onChange={(e, { value }) => handleWodInputChange(e, 'timer_id', value, wod.id)}
                        />
                      ) : (
                        wod.timer_name || 'No Timer'
                      )}
                    </td>
                    <td style={{
                      backgroundColor: isCountNullOrZero
                        ? '#FFF5BE'
                        : !wod.is_valid
                        ? '#FFC2BE'
                        : 'transparent',
                    }}>{wod.exercise_count}</td>
                    <td>
                      <div className="actions">
                        {editingWodId === wod.id ? (
                          <>
                            <button onClick={() => handleSaveWod(wod.id)} className="button">
                              <FaCheck title="Save WOD" />
                            </button>
                            <button onClick={handleCancelEdit} className="button">
                              <FaTimes title="Cancel Edit" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button onClick={() => handleCopy(wod.id)} className="button">
                              <FaCopy title="Copy ID" />
                            </button>
                            <button onClick={() => navigate(`/wod/${wod.id}`)} className="button">
                              <FaEye title="View WOD" />
                            </button>
                            <button onClick={() => setEditingWodId(wod.id)} className="button">
                              <FaPencilAlt title="Edit WOD" />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <FaChevronLeft />
          </button>
          {pagesArray.map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            <FaChevronRight />
          </button>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
}

export default WodPage;
