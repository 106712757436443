import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaCalendarAlt,
  FaUsers,
  FaMapMarkerAlt,
  FaUser,
  FaDumbbell,
  FaTimes,
  FaHome,
  FaListAlt, // Import new icon for WOD
} from "react-icons/fa"; // Import icons
import "./NavMenu.css";
import { useAuth } from "./AuthContext"; // Import your useAuth hook

const NavMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { logout } = useAuth();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="navbar">
        <button onClick={toggleMenu}>☰</button>
        Lifted Admin
      </nav>

      {/* Sidebar Menu (Only when open) */}
      <div className={`sidebar-menu ${isOpen ? "open" : ""}`}>
        <div className="title">
          <h2>Menu</h2>
        </div>
        <button onClick={toggleMenu} className="menu-close-button">
          <FaTimes />
        </button>
        <div className="menu-items">
          <Link to="/" className="menu-item" onClick={toggleMenu}>
            <FaHome className="menu-icon" /> Homepage
          </Link>
          <Link to="/timers" className="menu-item" onClick={toggleMenu}>
            <FaClock className="menu-icon" /> Timers
          </Link>
          <Link to="/wods" className="menu-item" onClick={toggleMenu}>
            <FaListAlt className="menu-icon" /> Workout of the Day
          </Link>
          <Link to="/workouts" className="menu-item" onClick={toggleMenu}>
            <FaCalendarAlt className="menu-icon" /> Workouts
          </Link>
          <Link to="/bookings" className="menu-item" onClick={toggleMenu}>
            <FaUsers className="menu-icon" /> Bookings
          </Link>
          <Link to="/locations" className="menu-item" onClick={toggleMenu}>
            <FaMapMarkerAlt className="menu-icon" /> Locations
          </Link>
          <Link to="/customers" className="menu-item" onClick={toggleMenu}>
            <FaUser className="menu-icon" /> Customers
          </Link>
          <Link to="/exercises" className="menu-item" onClick={toggleMenu}>
            <FaDumbbell className="menu-icon" /> Exercises
          </Link>
        </div>
        {/* Logout Button (Top Right) */}
        <button onClick={logout} className="logout-button">
          Log out
        </button>
      </div>
    </div>
  );
};

export default NavMenu;
