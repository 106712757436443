import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import api from './axiosConfig'; // Import axios for API requests
import "./WorkoutPlayer.css"; // Import the new CSS file
import moment from "moment-timezone"; // Import moment-timezone
import { FaCheckCircle, FaCircle } from 'react-icons/fa';

interface TimerData {
  intervals: {
    duration: number;
    intervalType: string;
    exercise_number: number;
    setNumber?: number;
    nextExercise?: number;
    nextSetNumber?: number;
  }[];
  name: string;
  total_duration: number;
}

interface WorkoutTimerData {
  timer: TimerData;
  uniqueExerciseCount: number;
  maxSetNumber: number;
}

interface WorkoutData {
  timer_id: number;
  start_datetime: string;
  coach_first_name: string;
  playlists: Record<string, string>;
}

interface Milestone {
  firstTimers: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[];
  otherMilestones: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[];
  [key: string]: { total_workouts: number; customer_id: number; first_name: string; last_name: string }[]; // Add this line

}

interface PersonalBest {
  customer_id: number;
  first_name: string;
  last_name: string;
  improvement_count: number;
}

function WorkoutPlayer() {
  const { locationId } = useParams<{ locationId: string }>();
  const workoutIdRef = useRef<string | null>(null);
  const [timerData, setTimerData] = useState<TimerData | null>(null);
  const [workoutData, setWorkoutData] = useState<WorkoutData | null>(null); // State to hold workout data
  const [currentIntervalIndex, setCurrentIntervalIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false); // Start the timer immediately
  const [isLoading, setIsLoading] = useState(true);
  const [milestones, setMilestones] = useState<Milestone | null>(null);
  const [personalBests, setPersonalBests] = useState<PersonalBest[] | null>(null);
  const [uniqueExerciseCount, setUniqueExerciseCount] = useState<number>(0);
  const [maxSetNumber, setMaxSetNumber] = useState<number>(0);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [lastPlayedSound, setLastPlayedSound] = useState<number | null>(null); // Track last played sound

  // Add sound declarations
  const soundGo = new Audio("/sounds/Sound_Go.mp3");
  const soundSwitch = new Audio("/sounds/Sound_Switch.mp3");
  const soundLongBreak = new Audio("/sounds/Sound_Long_Break.mp3");
  const soundWarmUp = new Audio("/sounds/Sound_Warm_Up.mp3");
  const soundWorkoutComplete = new Audio("/sounds/Sound_Workout_Complete.mp3");
  const soundWorkoutStart = new Audio("/sounds/Sound_Workout_Start.mp3");
  const soundGetReady = new Audio("/sounds/Sound_Get_Ready.mp3");
  const sound20 = new Audio("/sounds/Sound_20.mp3");
  const sound10 = new Audio("/sounds/Sound_10.mp3");
  const sound3 = new Audio("/sounds/Sound_3.mp3");
  const sound2 = new Audio("/sounds/Sound_2.mp3");
  const sound1 = new Audio("/sounds/Sound_1.mp3");
  const sound20sToGetReady = new Audio("/sounds/Sound_20s_To_Get_Ready.mp3");
  const soundBreak = new Audio("/sounds/Sound_Break.mp3");
  const soundPleaseMove = new Audio("/sounds/Sound_Please_Move.mp3");

  // State variables to manage display transitions and progress bars
  const [displayState, setDisplayState] = useState("countdown"); // timer, firstTimers, milestones, personalBests

  // Progress circle design elements
  const circleCircumference = 2 * Math.PI * 551; // Updated radius to match SVG

  const strokeDashArray =
    timerData?.intervals && timeLeft !== null
      ? `${((timerData.intervals[currentIntervalIndex].duration - timeLeft) /
        timerData.intervals[currentIntervalIndex].duration) *
      circleCircumference
      } ${circleCircumference}`
      : `0 ${circleCircumference}`;

  useEffect(() => {
    if (timerData && timeLeft === 0 && currentIntervalIndex === 0) {
      setTimeLeft(timerData?.intervals[0].duration);
    }
  }, [timerData]);

  // CODE TO LOAD WORKOUT INFORMATION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const workoutId = workoutIdRef.current;
        if (!workoutId) return;

        // 1. Fetch workout data
        const workoutResponse = await api.get<WorkoutData>(`/workouts/${workoutId}`);
        setWorkoutData(workoutResponse.data);

        // Call checkWorkoutStart after setting workoutData
        checkWorkoutStart(); // Ensure this runs after workoutData is set

        try {
          const response = await api.get<WorkoutTimerData>(`/workouts/${workoutId}/timer`);
          const workoutTimerData = response.data;
          setTimerData(workoutTimerData.timer);
          setUniqueExerciseCount(workoutTimerData.uniqueExerciseCount);
          setMaxSetNumber(workoutTimerData.maxSetNumber);
        } catch (error) {
          console.error("Error fetching workout timer:", error);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (workoutIdRef.current) {
      fetchData();
    }
  }, [workoutIdRef.current]); // Run only when workoutId changes

  const formattedTime = workoutData
    ? moment
      .tz(workoutData.start_datetime, "UTC")
      .tz("Europe/Berlin") // Convert to Berlin timezone
      .format("HH:mm") // Customize the format as needed
    : "";

  // CODE TO DISPLAY THE TIMER
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!timerData) {
      return;
    }

    if (
      isRunning &&
      timerData &&
      currentIntervalIndex < timerData.intervals.length
    ) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 1) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(intervalId);
            if (currentIntervalIndex < timerData.intervals.length - 1) {
              const newIntervalIndex = currentIntervalIndex + 1;
              const newTimeLeft =
                timerData.intervals[newIntervalIndex].duration;

              setCurrentIntervalIndex(newIntervalIndex);
              setTimeLeft(newTimeLeft);
            } else {
              clearInterval(intervalId);
              setIsRunning(false);

              sendWorkoutAttendance();
              setDisplayState("thankYou");

              return 0;
            }
          }
          return 0; // Ensure a number is always returned
        });
      }, 1000); // Update every 1000ms (1 second)
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning, currentIntervalIndex, timerData, workoutIdRef.current]);

  const checkWorkoutStart = () => {
    if (!isRunning && currentIntervalIndex === 0) {
      const startTime = moment(workoutData?.start_datetime);
      const now = moment();
      const diffInMinutes = startTime.diff(now, 'minutes');
      const diffInSeconds = startTime.diff(now, 'seconds');

      if (diffInMinutes > 0 && diffInSeconds > 0) {
        setCountdown(diffInSeconds); // Set countdown in seconds
        setDisplayState("countdown"); // Set display state to countdown
      } else {
        setCountdown(null); // Reset countdown if conditions are not met
        setDisplayState("timer"); // Reset display state to timer
      }
    }
  };

  // WebSocket Connection
  const [ws, setWs] = useState<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Add a new ref to track if an interval was skipped
  const wasSkippedRef = useRef(false);

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = process.env.NODE_ENV === 'production'
        ? `wss://${new URL(process.env.REACT_APP_API_URL || '').host}`
        : "ws://localhost:5000";

      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log("WorkoutPlayer connected to WebSocket server");
        newWs.send(JSON.stringify({ type: "join", locationId }));
      };

      newWs.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (
          (data.type === "timerUpdate" || data.type === 'refreshTimer' || data.type === 'resetTimer') &&
          data.locationId === locationId
        ) {
          // Use the skipped flag from the server instead of trying to detect it
          if (data.skipped) {
            wasSkippedRef.current = true;
          } else {
            wasSkippedRef.current = false;
          }
          
          // Check if timeLeft is 1, 2, or 3 and ignore the message if true
          if (!(data.type === "timerUpdate" && [1, 2, 3].includes(data.timerState.timeLeft))) {
            workoutIdRef.current = data.workoutId;
            setCurrentIntervalIndex(data.timerState.intervalIndex);
            setTimeLeft(data.timerState.timeLeft);
          }
          // Check if the isRunning state has changed before updating
          if (data.timerState.isRunning !== isRunning) {
            checkWorkoutStart();
          }

          setIsRunning(data.timerState.isRunning);
        }

        // Handle countdown sync messages
        if (data.type === "countdownSync" && data.locationId === locationId) {
          setCountdown(data.countdown); // Update countdown value
        }
      };

      newWs.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        newWs.close();
      };

      setWs(newWs);
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [locationId]);

  const sendWorkoutAttendance = async () => {
    try {
      const workoutId = workoutIdRef.current;
      if (!workoutId) return;

      const response = await api.post(`/bsport/send_attendance/${workoutId}`);
      console.log("Attendance updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating attendance:", error);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const intervalType = timerData?.intervals?.[
    currentIntervalIndex
  ]?.intervalType
    ?.replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const displayText = timerData?.intervals?.[currentIntervalIndex]
    ?.exercise_number
    ? "Exercise"
    : intervalType === "Change Station"
      ? "Switch"
      : intervalType?.includes("Pause")
        ? "Break"
        : intervalType;

  const getStrokeColorForInterval = (intervalType: string) => {
    if (intervalType.includes("main") || intervalType.includes("challenge")) {
      return "var(--color-neon-blue)";
    } else if (
      intervalType.includes("break") ||
      intervalType.includes("switch") ||
      intervalType.includes("pause") ||
      intervalType.includes("stretching") ||
      intervalType.includes("breathing") ||
      intervalType.includes("station")
    ) {
      return "var(--color-grey)";
    } else if (intervalType.includes("warmup")) {
      return "var(--color-yellow)";
    } else {
      return "var(--color-cream)";
    }
  };

  const currentIntervalType =
    timerData?.intervals?.[currentIntervalIndex]?.intervalType || "";
  const strokeColor = getStrokeColorForInterval(currentIntervalType);

  // Function to calculate the segments for the progress bar
  const calculateSegments = (intervals: { duration: number; intervalType: string }[]) => {
    if (!intervals) return [];
    const segments: { duration: number; intervalType: string }[] = [];
    let currentSegment: { duration: number; intervalType: string } | null = null;

    intervals.forEach((interval, index) => {
      const isMainSet = interval.intervalType.includes("main set");
      const isBreak = interval.intervalType.includes("break");
      const isSwitchOrPause = interval.intervalType.includes("switch") || interval.intervalType.includes("pause");

      if (isMainSet || isSwitchOrPause) {
        if (!currentSegment) {
          currentSegment = { duration: 0, intervalType: "main set" };
        }
        currentSegment.duration += interval.duration;
      } else {
        if (currentSegment) {
          segments.push(currentSegment);
          currentSegment = null;
        }
        segments.push({ duration: interval.duration, intervalType: interval.intervalType });
      }

      if (isBreak && currentSegment) {
        segments.push(currentSegment);
        currentSegment = null;
      }

      if (index === intervals.length - 1 && currentSegment) {
        segments.push(currentSegment);
      }
    });
    return segments;
  };

  const segments = calculateSegments(timerData?.intervals || []);

  // Function to calculate the fill width for each segment
  const calculateFillWidth = (segments: { duration: number; intervalType: string }[], totalDurationPassed: number) => {
    let remainingDuration = totalDurationPassed;
    return segments.map(segment => {
      const fillWidth = Math.min(segment.duration, remainingDuration);
      remainingDuration -= fillWidth;
      return {
        ...segment,
        fillWidth: (fillWidth / segment.duration) * 100
      };
    });
  };

  const totalDurationPassed = (timerData?.intervals?.slice(0, currentIntervalIndex) ?? [])
    .reduce((acc, interval) => acc + (interval?.duration ?? 0), 0)
    + ((timerData?.intervals?.[currentIntervalIndex]?.duration ?? 0) - timeLeft);

  const filledSegments = calculateFillWidth(segments, totalDurationPassed);

  useEffect(() => {
    if (timerData && timerData.intervals.length > 0) {
      const currentInterval = timerData.intervals[currentIntervalIndex];

      // Only play sounds if the interval wasn't skipped
        // Play sound based on interval type and time left
        if (currentInterval.intervalType.includes("main") && timeLeft === 20) {
          sound20.play();
        } else if (["switch", "pause", "long break", "setup"].includes(currentInterval.intervalType) && timeLeft === 10) {
          sound10.play();
        } else if (timeLeft === currentInterval.duration && !wasSkippedRef.current) {
          if (currentInterval.intervalType.includes("main")) {
            soundGo.play();
          } else {
            switch (currentInterval.intervalType) {
              case "switch":
                soundSwitch.play();
                break;
              case "pause":
                soundBreak.play();
                break;
              case "break":
                soundBreak.play();
                break;
              case "change_station":
                soundBreak.play();
                setTimeout(() => {
                  soundPleaseMove.play();
                }, 2000);
                break;
              case "warmup":
                soundWarmUp.play();
                break;
              case "setup":
                soundGetReady.play();
                break;
              case "challenge":
                soundGo.play();
                // Check if this is the last interval
                if (currentIntervalIndex === timerData.intervals.length - 1) {
                  soundWorkoutComplete.play();
                }
                break;
              default:
                break;
            }
          }
        }

        // Play Workout Start sound one second after the first interval starts
        if (currentIntervalIndex === 0 && timeLeft === currentInterval.duration - 1) {
          soundWorkoutStart.play();
        }

        // Play "get ready" sound when 20 seconds are left in a long break
        if (currentInterval.intervalType.includes("change") && timeLeft === 20) {
          sound20sToGetReady.play();
        }

        // Play countdown sounds
        if (timeLeft <= 3 && timeLeft > 0 && !["break", "stretching", "breathing"].some(type => currentInterval.intervalType.includes(type))) {
          if (lastPlayedSound !== timeLeft) {
            switch (timeLeft) {
              case 3:
                sound3.play();
                break;
              case 2:
                sound2.play();
                break;
              case 1:
                sound1.play();
                break;
            }
            setLastPlayedSound(timeLeft);
          }
        } else {
          setLastPlayedSound(null);
        }

    }
  }, [timeLeft, currentIntervalIndex, timerData]);

  // Update the UI to display the exercise progress with blinking animation
  const renderExerciseProgress = () => {
    if (!timerData) return null;

    return (
      <div className="exercise-progress-list">
        <div className="exercise-progress-header">
          <div className="exercise-element">Exercises</div>
          {[...Array(maxSetNumber)].map((_, index) => (
            <div className="set-element" key={index}>Set {index + 1}</div>
          ))}
        </div>
        {Array.from({ length: uniqueExerciseCount }, (_, exerciseNumber) => (
          <div key={exerciseNumber} className="exercise-progress-row">
            <div className="exercise-element">Exercise {exerciseNumber + 1}</div>
            {[...Array(maxSetNumber)].map((_, setNumber) => {
              const isBreakInterval = timerData.intervals.some(interval =>
                ["break", "pause", "change_station", "setup"].includes(interval.intervalType)
              );
              return (
                <div
                  key={setNumber}
                  className="set-element"
                >
                  {timerData.intervals.some((interval, index) =>
                    interval.exercise_number === exerciseNumber + 1 &&
                    interval.setNumber === setNumber + 1 &&
                    currentIntervalIndex >= index) ? <FaCheckCircle /> : ""}
                  {isBreakInterval && timerData.intervals.some((interval, index) =>
                    interval.nextExercise === exerciseNumber + 1 &&
                    interval.nextSetNumber === setNumber + 1 &&
                    currentIntervalIndex === index) ? <FaCircle className="blinking" /> : ""}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    console.log("Re-running check workout start with wrkoutdata:", workoutData)
    console.log("Timerstate", isRunning, currentIntervalIndex)
    checkWorkoutStart();
  }, [isRunning, currentIntervalIndex, workoutData]); // Ensure this runs when these dependencies change

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown !== null) {
        setCountdown((prevCountdown) => {
          if (prevCountdown !== null && prevCountdown > 0) {
            return prevCountdown - 1; // Decrement countdown
          }
          setDisplayState("timer"); // Reset display state to timer when countdown reaches zero
          return null; // Set to null when countdown reaches zero
        });
      }
    }, 1000); // Update countdown every second

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, workoutData]); // Run this effect when countdown or workoutData changes

  return (
    <div
      className="workout-player-container"
      style={{
        background: displayState === "timer" ? "var(--color-light-blue)" : "var(--color-cream)",
      }}
    >
      <svg
        className="lifted-logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 249 44"
        fill="currentColor" // This will inherit the color from CSS
      >
        <path d="M0 43.52V0H7.296V37.376H25.216L19.0703 43.52H0Z" fill="currentColor" />
        <path d="M43.4375 43.52V0H50.7335V43.52H43.4375Z" fill="currentColor" />
        <path d="M70.0625 43.52V0H99.1185V6.144H77.3585V19.008H99.1185V25.152H77.3585V43.52H70.0625Z" fill="currentColor" />
        <path d="M127.802 43.52V6.144H116.283V0H146.555V6.144H135.098V43.52H127.802Z" fill="currentColor" />
        <path d="M164.688 43.52V0H193.616V6.144H171.984V18.304H193.616V24.32H171.984V37.376H193.616V43.52H164.688Z" fill="currentColor" />
        <path d="M212.5 43.52V0H227.156C239.764 0 248.596 8.768 248.596 21.504C248.596 34.56 239.764 43.52 227.156 43.52H212.5ZM219.796 37.376H226.196C235.412 37.376 241.364 31.04 241.364 21.504C241.364 12.288 235.412 6.144 226.196 6.144H219.796V37.376Z" fill="currentColor" />
      </svg>
      {isLoading ? (
        <p>Loading workout...</p>
      ) : (
        <>
          {displayState === "timer" && (
            <>
              <div className="workout-center-content">
                <div className="left-column">
                  <div className="workout-timer-circle">
                    <svg width="1200" height="1200" viewBox="0 0 1200 1200">
                      {/* Background Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="590"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Content Circle */}
                      <circle
                        cx="600"
                        cy="600"
                        r="482"
                        fill="none"
                        stroke="var(--color-dark-blue)"
                        strokeWidth="10"
                      />
                      {/* Progress Circle */}
                      <circle
                        className="workout-timer-circle-progress"
                        cx="600"
                        cy="600"
                        r="536"
                        fill="none"
                        stroke={strokeColor}
                        strokeWidth="98"
                        strokeLinecap="square"
                        style={{
                          strokeDasharray: strokeDashArray,
                          transform: "rotate(270deg)",
                          transformOrigin: "center",
                        }}
                      />
                    </svg>
                    <div className="workout-timer-text">
                      <div className="workout-description-text">
                        {displayText}
                      </div>
                      <div className="workout-time-left">
                        {formatTime(timeLeft)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-column">
                  {renderExerciseProgress()}
                </div>
              </div>

              <div className="progress-bar-container">
                {filledSegments.map((segment, index) => (
                  <div
                    key={index}
                    className="progress-bar-segment"
                    style={{
                      width: `${(segment.duration / (timerData?.total_duration ?? 0)) * 100}%`
                    }}
                  >
                    <div
                      className="progress-bar-fill"
                      style={{
                        width: `${segment.fillWidth}%`
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </>
          )}
          {displayState === "thankYou" && ( // Thank you display
            <div className="celebration-container">
              <h2 className="celebration-subheader" style={{ color: 'var(--color-neon-blue)' }}>Thank you!</h2>
              <h3 className="celebration-header" style={{ color: 'var(--color-dark-blue)' }}>See you <span style={{ fontFamily: 'PPEditorialNew' }}>soon</span></h3>
            </div>
          )}
          {["thankYou"].includes(displayState) && (
            <div className="workout-footer">
              <p>Coach <span style={{ fontFamily: 'PPEditorialNew' }}>{workoutData?.coach_first_name}</span></p>
              <p style={{ fontSize: "48px" }}>
                {timerData?.name} at {formattedTime}
              </p>
            </div>
          )}
          {displayState === "countdown" && countdown !== null && (
            <div className="countdown-container">
              Workout starts in
              <div className="countdown-timer">
                <span className="minutes">{String(Math.floor(countdown / 60)).padStart(2, '0')}</span>:
                <span className="seconds">{String(countdown % 60).padStart(2, '0')}</span>
              </div>
              {countdown < 300 && ( // 300 seconds = 5 minutes
                <div className="spot-pick-text">
                  Have you picked a spot?
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default WorkoutPlayer;